import React, { ReactNode, createContext } from 'react';
import {
  JiraService,
  ProjectService,
  HttpService,
  ConfluenceService,
  GitHubService,
  CollaboratorsService,
  MetricsService,
  ReportsService,
  SummarAIzeService,
} from '../services/';
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { ComponentService } from '../services/component-service';

type ServiceContextType = {
  services: {
    jiraService: JiraService;
    projectService: ProjectService;
    confluenceService: ConfluenceService;
    gitHubService: GitHubService;
    componentService: ComponentService;
    collaboratorsService: CollaboratorsService;
    metricsService: MetricsService;
    reportsService: ReportsService;
    summarAIzeService: SummarAIzeService;
  };
};
type Props = {
  children: ReactNode;
};
const AppContext = createContext<ServiceContextType>({} as ServiceContextType);
const { Provider } = AppContext;
const AppProvider = ({ children }: Props) => {
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const httpService = new HttpService(fetchApi, discoveryApi);
  const services = {
    jiraService: new JiraService(httpService),
    projectService: new ProjectService(httpService),
    confluenceService: new ConfluenceService(httpService),
    gitHubService: new GitHubService(httpService),
    componentService: new ComponentService(httpService),
    collaboratorsService: new CollaboratorsService(httpService),
    metricsService: new MetricsService(httpService),
    reportsService: new ReportsService(httpService),
    summarAIzeService: new SummarAIzeService(httpService),
  };
  return <Provider value={{ services }}>{children}</Provider>;
};
export { AppContext, AppProvider };
