import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { alertApiRef, githubAuthApiRef, ProfileInfo, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { DEFAULT_NAMESPACE } from '@backstage/catalog-model';
import { ResponseErrorPanel, Progress } from '@backstage/core-components';
import { AlertSnackbar, AppContext, GitHubItem } from '@softwareone/plugin-project-management-react';
import { RepoTable } from '../table/RepoTable';
import { useConfirm } from '../../hook/useConfirm';
import { extractGroupAdminInfo, UserObject } from '../utils/utils';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { SystemEntity } from '@softwareone/plugin-project-management-common';

export const GitHubRepoCard = () => {
  const { entity } = useEntity();
  const { ConfirmationDialog, confirm } = useConfirm();
  const catalogApi = useApi(catalogApiRef);
  const gitHubApi = useApi(githubAuthApiRef);
  const alertApi = useApi(alertApiRef);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [templateOptionsLoading, setTemplateOptionsLoading] = useState<boolean>(false);
  const [templateOptions, setTemplateOptions] = useState<string[]>([]);
  const [sourceProjectKeyOptions, setSourceProjectKeyOptions] = useState<{ label: string; value: string }[]>([]);
  const {
    services: { gitHubService, projectService },
  } = useContext(AppContext);
  type CreateForm = {
    kind: string;
    type: string;
    lifecycle: string;
    contentType: string;
    componentName: string;
    suffix: string;
    template?: string;
    isFormValid: boolean;
  };

  type ForkForm = Pick<CreateForm, 'kind' | 'type' | 'isFormValid' | 'lifecycle'> & {
    sourceProjectKey: string;
    sourceComponentKey: string;
  };

  const [forkForm, setForkForm] = useState<ForkForm>({
    sourceProjectKey: '',
    sourceComponentKey: '',
    kind: '',
    type: '',
    lifecycle: '',
    isFormValid: false,
  });

  const [createForm, setCreateForm] = useState<CreateForm>({
    kind: '',
    type: '',
    lifecycle: '',
    contentType: '',
    componentName: '',
    suffix: '',
    isFormValid: false,
  });
  const { backstageIdentity, profile: backstageProfile } = useUserProfile();
  const formattedBackstageProfile = {
    id: '',
    authProvider: '',
    ...backstageProfile,
  };
  const { name: projectKey, creatorActiveDirectoryId } = entity.metadata as SystemEntity;

  const handleChangeTab = (_event: any, newValue: number) => {
    setCurrentTab(newValue);
  };

  const deleteMessage = (repoName: string) => {
    return `You are about to delete component ${repoName} and its GitHub repository. Are you sure you want to continue?`;
  };
  const archiveMessage = (repoName: string, archived: boolean) => {
    return `You are about to ${
      archived ? 'archive' : 'unarchive'
    } GitHub repository ${repoName}. Are you sure you want to continue?`;
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const onSubmitCreate = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    const requestBody = {
      projectKey: projectKey,
      kind: createForm.kind,
      componentName: createForm.componentName,
      suffix: createForm.suffix,
      type: createForm.type,
      lifecycle: createForm.lifecycle,
      gitHubRepositoryConfiguration: {
        contentType: createForm.contentType,
        templateRepository: createForm.template,
      },
    };
    try {
      await gitHubService.createRepository(requestBody);
      setMessage('Repository is successfully created');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      let errorMessage = 'An error occurred during repository creation';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      console.error(err);
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const onSubmitFork = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitLoading(true);
    const requestBody = {
      projectKey: projectKey,
      kind: forkForm.kind,
      type: forkForm.type,
      lifecycle: forkForm.lifecycle,
      sourceProjectKey: forkForm.sourceProjectKey,
      sourceComponentKey: forkForm.sourceComponentKey,
    };
    try {
      await gitHubService.createForkedRepository(requestBody);
      setMessage('Repository is successfully created');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      let errorMessage = 'An unknown error occurred during repository creation';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      console.error(err);
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const handleDelete = async (data: string) => {
    const confirmStatus = await confirm(deleteMessage(data), 'GitHub Repository Deletion');
    if (!confirmStatus) {
      return;
    }
    setSubmitLoading(true);
    try {
      await gitHubService.deleteRepository(projectKey, data);
      setMessage('Repository is successfully deleted');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      console.error(err);
      let errorMessage = 'An unknown error occured during repository deletion';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const handleArchive = async (data: string, archived: boolean) => {
    const confirmStatus = await confirm(archiveMessage(data, archived), 'GitHub Repository Archive');
    if (!confirmStatus) {
      return;
    }
    setSubmitLoading(true);
    try {
      await gitHubService.archiveRepository(projectKey, data, archived);
      setMessage(`Repository is successfully ${archived ? 'archived' : 'unarchived'}`);
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      console.error(err);
      let errorMessage = `An unknown error occurred during ${archived ? 'archive' : 'unarchive'} process`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const getTemplateOptions = useCallback(
    async (signal: AbortSignal) => {
      if (createForm.contentType === 'From Template' && !templateOptions.length) {
        try {
          setTemplateOptionsLoading(true);
          const data = await gitHubService.getTemplates(signal);
          setTemplateOptions(data);
          setTemplateOptionsLoading(false);
        } catch (err) {
          let errorMessage = 'An error occured during server request';
          if (err instanceof Error) {
            if (err.name === 'AbortError') {
              return;
            }
            errorMessage = err.message;
          }
          setTemplateOptionsLoading(false);
          alertApi.post({ message: errorMessage, severity: 'error' });
        }
      }
    },
    [createForm.contentType]
  );

  useEffect(() => {
    const controller = new AbortController();
    getTemplateOptions(controller.signal);
    return () => controller?.abort();
  }, [getTemplateOptions]);

  const { loading, error, value } = useAsync(async () => {
    const catalogPromise = catalogApi.getEntities({
      filter: {
        kind: ['API', 'Component'],
        'spec.system': [projectKey, `${entity.metadata.namespace || DEFAULT_NAMESPACE}/${projectKey}`],
      },
    });

    const getSourceProjectKeyOptions = async () => {
      const systems = await catalogApi.getEntities({
        filter: {
          kind: ['System'],
        },
      });

      const newSourceProjectKeyOptions = systems.items
        .filter(system => system.metadata.name !== projectKey)
        .map(system => ({
          label: `${system.metadata.title} (${system.metadata.name})`,
          value: system.metadata.name,
        }));
      setSourceProjectKeyOptions(newSourceProjectKeyOptions);
    };

    if (!Object.keys(backstageProfile).length) {
      return;
    }

    type TeamsInfoResponse = Promise<{
      gitHubOwners: { login: string }[];
      message?: string;
    }>;
    if (formattedBackstageProfile.authProvider === 'GitHub') {
      const teamsInfoPromise = (await projectService.getProjectTeamInfo(projectKey)) as Promise<TeamsInfoResponse>;

      const profileInfoPromise = gitHubApi.getProfile();
      return Promise.all([catalogPromise, teamsInfoPromise, profileInfoPromise, getSourceProjectKeyOptions()]);
    }
    return Promise.all([catalogPromise, {} as TeamsInfoResponse, {} as ProfileInfo]);
  }, [catalogApi, projectKey, backstageProfile]);

  if (loading || !value || submitLoading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const [componentData, teamData, profileData] = value;

  if (teamData.message) {
    return <AlertSnackbar open={!open} onClose={handleClose} severity="error" message="An error occurred" />;
  }
  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  const renderTabPanel = (index: any, content: any) => (
    <div hidden={currentTab !== index}>{currentTab === index && <Box>{content}</Box>}</div>
  );

  const isOwner = () => {
    if (formattedBackstageProfile.authProvider === 'GitHub') {
      const isAdmin = hasGithubAdmins;
      const isTeamOwner = teamData.gitHubOwners.some(data => data.login === profileData?.displayName);
      return isTeamOwner || isAdmin;
    }
    return formattedBackstageProfile.id === creatorActiveDirectoryId;
  };

  const handleCreateChange = (e: Omit<CreateForm, 'isFormValid'>) => {
    setCreateForm(prevState => ({ ...prevState, ...e }));
  };
  const handleForkChange = (e: Omit<ForkForm, 'isFormValid'>) => {
    setForkForm(prevState => ({ ...prevState, ...e }));
  };
  const onCreateValidityChange = (e: boolean) => {
    setCreateForm(prevState => ({ ...prevState, isFormValid: e }));
  };

  const onForkValidityChange = (e: boolean) => {
    setForkForm(prevState => ({ ...prevState, isFormValid: e }));
  };
  const createProps = {
    propType: 'Create' as const,
    kind: createForm.kind,
    componentName: createForm.componentName,
    suffix: createForm.suffix,
    type: createForm.type,
    lifecycle: createForm.lifecycle,
    contentType: createForm.contentType,
    template: createForm.template,
    templateOptions,
    templateOptionsLoading,
    isDisabled: false,
    onChange: handleCreateChange,
    onValidityChange: onCreateValidityChange,
  };

  const forkProps = {
    propType: 'Fork' as const,
    kind: forkForm.kind,
    type: forkForm.type,
    lifecycle: forkForm.lifecycle,
    sourceComponentKey: forkForm.sourceComponentKey,
    isDisabled: false,
    onChange: handleForkChange,
    onValidityChange: onForkValidityChange,
    sourceProjectKeyOptions,
    sourceProjectKey: forkForm.sourceProjectKey,
  };
  return (
    <>
      {isOwner() && (
        <>
          <Typography variant="h3">New Repository</Typography>
          <Typography>&nbsp;</Typography>
          <Tabs value={currentTab} onChange={handleChangeTab} style={{ marginLeft: '0' }}>
            <Tab label="Create" />
            <Tab label="Fork" />
          </Tabs>
          <Typography>&nbsp;</Typography>
          {renderTabPanel(
            0,
            <form onSubmit={onSubmitCreate}>
              <GitHubItem {...createProps} />
              <Typography>
                <i>*It takes Backstage some time to sync the catalog and list the new repository.</i>
              </Typography>
              <Typography>&nbsp;</Typography>
              <Button variant="contained" type="submit" disabled={!createForm.isFormValid}>
                Create
              </Button>
            </form>
          )}
          {renderTabPanel(
            1,
            <form onSubmit={onSubmitFork}>
              <GitHubItem {...forkProps} />
              <Typography>
                <i>*It takes Backstage some time to sync the catalog and list the new repository.</i>
              </Typography>
              <Typography>&nbsp;</Typography>
              <Button variant="contained" type="submit" disabled={!forkForm.isFormValid}>
                Create
              </Button>
            </form>
          )}
        </>
      )}
      <Typography>&nbsp;</Typography>
      <Typography>&nbsp;</Typography>
      <Typography variant="h3">Existing Repositories</Typography>
      <Typography>&nbsp;</Typography>
      <RepoTable
        handleDelete={handleDelete}
        handleArchive={handleArchive}
        rows={componentData?.items}
        withActions={isOwner()}
      />
      <ConfirmationDialog />
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </>
  );
};
