import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useConfluenceOrganizationUsers } from '../hook/useConfluenceOrganizationUsers';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Progress } from '@backstage/core-components';
import { AlertSnackbar, AppContext, ConfluenceItem } from '@softwareone/plugin-project-management-react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import {
  addTeamMembers,
  extractGroupAdminInfo,
  processProjectTeamInfo,
  TeamResponse,
  UserObject,
} from '../utils/utils';
import { SystemEntity } from '@softwareone/plugin-project-management-common';

type UserResponse = {
  accountId: string;
  publicName: string;
};

export const ConfluenceSpace = () => {
  const [allUsers, setAllUsers] = useState<UserResponse[]>([]);
  const [confluenceAdministrators, setConfluenceAdministrators] = useState<string[]>([]);
  const [confluenceContributors, setConfluenceContributors] = useState<string[]>([]);
  const [confluenceCustomers, setConfluenceCustomers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [users, setUsers] = useState<string[]>([]);
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [visibleToEveryone, setVisibleToEveryone] = useState<boolean | undefined>(true);
  const { entity } = useEntity();
  const {
    jiraProjectKey,
    confluenceSpaceKey,
    name: currentSystemName,
    customer,
    title,
    creatorActiveDirectoryId,
  } = entity.metadata as SystemEntity;
  const [isFormValid, setIsFormValid] = useState(!!jiraProjectKey);
  const [spaceKey, setSpaceKey] = useState<string>((jiraProjectKey as string) || '');
  const [prevUsers, setPrevUsers] = useState<{
    gitHubContributors: { login: string }[];
    gitHubReaders: { login: string }[];
    gitHubOwners: { login: string }[];
  }>();
  const [prevUsersLoading, setPrevUsersLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState<boolean>(false);
  const {
    services: { confluenceService, projectService, jiraService },
  } = useContext(AppContext);
  const projectKey = currentSystemName;
  const gitHubApi = useApi(githubAuthApiRef);
  const { value, error } = useConfluenceOrganizationUsers();
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };

  const generateSpaceName = () => `${customer}-${title}`;
  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setStatus('error');
      setMessage(error.message);
      setOpen(true);
      return;
    }
    if (!value) return;
    type usersResponse = {
      data: {
        groupName: string;
        users: {
          accountId: string;
          publicName: string;
        }[];
      }[];
    };
    setAllUsers((value as UserResponse[]).map(val => val));
    setUsers((value as { publicName: string }[]).map(val => val.publicName));
    if (!confluenceSpaceKey) {
      setIsLoading(false);
      return;
    }
    setIsFormValid(true);
    let isSubscribed = true;
    const fetchUsers = async () => {
      try {
        if (isSubscribed) {
          const teamMembers = await confluenceService.getTeamMembers(projectKey);
          const json = teamMembers as usersResponse;
          json.data.forEach(group => {
            if (group.groupName.includes('administrators')) {
              setConfluenceAdministrators(group.users.map(user => user.publicName));
            }
            if (group.groupName.includes('contributors')) {
              setConfluenceContributors(group.users.map(user => user.publicName));
            }
            if (group.groupName.includes('customers')) {
              setConfluenceCustomers(group.users.map(user => user.publicName));
            }
          });
          setIsLoading(false);
        }
      } catch (err) {
        const errorMessage = err instanceof Response ? err.statusText : 'Unknown error occurred';
        setMessage(errorMessage);
        setStatus('error');
        setOpen(true);
        setIsLoading(false);
      }
    };
    fetchUsers();

    return () => {
      isSubscribed = false;
    };
  }, [value, error]);

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    const isUserOwner = async () => {
      if (formattedProfile.authProvider === 'GitHub') {
        const profileData = await gitHubApi.getProfile();

        const teamData: TeamResponse = await processProjectTeamInfo(
          projectService,
          currentSystemName,
          hasGithubAdmins,
          setIsOwner,
          profileData
        );
        setPrevUsers({
          gitHubContributors: teamData.gitHubContributors,
          gitHubReaders: teamData.gitHubReaders,
          gitHubOwners: teamData.gitHubOwners,
        });
        return;
      }

      if (formattedProfile.id === creatorActiveDirectoryId) {
        setIsOwner(true);
        const data = await projectService.getProjectTeamInfo(projectKey);
        setPrevUsers(data);
      }
    };

    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  type ChangeData = {
    confluenceAdministrators: string[];
    confluenceContributors: string[];
    confluenceCustomers: string[];
    confluenceSpaceKey?: string;
    visibleToEveryone?: boolean;
  };
  const handleChange = (e: ChangeData) => {
    setConfluenceAdministrators(e.confluenceAdministrators);
    setConfluenceContributors(e.confluenceContributors);
    setConfluenceCustomers(e.confluenceCustomers);
    setSpaceKey(e.confluenceSpaceKey || '');
    setVisibleToEveryone(e.visibleToEveryone);
  };
  const handleValidityChange = (e: boolean) => {
    setIsFormValid(e);
  };

  const formatUsers = () => {
    return {
      confluenceAdministrators: allUsers
        .filter(user => confluenceAdministrators.includes(user.publicName))
        .map(user => user.accountId),
      confluenceContributors: allUsers
        .filter(user => confluenceContributors.includes(user.publicName))
        .map(user => user.accountId),
      confluenceCustomers: allUsers
        .filter(user => confluenceCustomers.includes(user.publicName))
        .map(user => user.accountId),
    };
  };

  const handleUpdateSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const updateTeamMembers = { projectKey, ...formatUsers() };
      await confluenceService.updateTeamMembers(updateTeamMembers);
      setIsLoading(false);
      setMessage('Users are successfully assigned');
      setStatus('success');
      setOpen(true);
    } catch (err) {
      console.error('Error:', err);
      setMessage('An error occurred during the Confluence space update');
      setStatus('error');
      setIsLoading(false);
      setOpen(true);
    }
  };
  const handleCreateSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const createConfluenceSpace = {
        visibleToEveryone: !!visibleToEveryone,
        projectKey,
        confluenceSpaceKey: spaceKey,
        ...formatUsers(),
      };
      await confluenceService.createConfluenceSpace(createConfluenceSpace);
      setIsLoading(false);
      setMessage('Users are successfully assigned. It takes time to be in sync with SoftwareOne DevOps platform');
      setStatus('success');
      setOpen(true);
    } catch (err) {
      console.error('Error:', err);
      setMessage('An error occurred during the Confluence space creation');
      setStatus('error');
      setIsLoading(false);
      setOpen(true);
    }
  };

  const getEmailsUserNames = async () => {
    try {
      setPrevUsersLoading(true);
      const data = projectService.getOrgUsers();
      setPrevUsersLoading(false);
      return data;
    } catch (e1) {
      console.error('Fetch error:', e1);
    }
  };

  const getJiraUserNames = async () => {
    try {
      return await jiraService.getJiraOrgUsers();
    } catch (e2) {
      console.error('Fetch error:', e2);
    }
  };

  const performActions = async () => {
    setDispatchLoading(true);
    const userEmail = await getEmailsUserNames();
    const jiraEmail = await getJiraUserNames();
    if (!prevUsersLoading) {
      addTeamMembers(
        userEmail,
        jiraEmail,
        prevUsers,
        setConfluenceAdministrators,
        setConfluenceContributors,
        setConfluenceCustomers
      );
    }
    setDispatchLoading(false);
  };

  if (isLoading || dispatchLoading) {
    return <Progress />;
  }

  if (!confluenceSpaceKey) {
    return (
      <>
        <form onSubmit={handleCreateSubmit}>
          <Box>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 1,
              }}
            >
              <ConfluenceItem
                confluenceSpaceName={generateSpaceName()}
                confluenceSpaceKey={spaceKey}
                confluenceAdministrators={confluenceAdministrators}
                confluenceContributors={confluenceContributors}
                confluenceCustomers={confluenceCustomers}
                withSharedJiraProject={!!jiraProjectKey}
                visibleToEveryone={visibleToEveryone}
                onChange={handleChange}
                onValidityChange={handleValidityChange}
                isDisabled={!isOwner}
                userList={users}
                getTeamMembersForConfluence={performActions}
              />
              <Button sx={{ m: '10px' }} variant="outlined" type="submit" disabled={!isFormValid || !isOwner}>
                Create
              </Button>
            </Paper>
          </Box>
        </form>
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleUpdateSubmit}>
        <Box>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 1,
            }}
          >
            <ConfluenceItem
              confluenceAdministrators={confluenceAdministrators}
              confluenceContributors={confluenceContributors}
              confluenceCustomers={confluenceCustomers}
              userList={users}
              enableSpaceInfo={false}
              isDisabled={!isOwner}
              onChange={handleChange}
              getTeamMembersForConfluence={performActions}
              onValidityChange={handleValidityChange}
            />
            <Button sx={{ m: '10px' }} variant="outlined" type="submit" disabled={!isFormValid || !isOwner}>
              Save
            </Button>
          </Paper>
        </Box>
      </form>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </>
  );
};
