import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
} from '@material-ui/core';
import { columns, createData, TableRows } from './utils';
import { Progress } from '@backstage/core-components';
import FilterTable from './FilterTable';
import { AlertSnackbar, AppContext } from '@softwareone/plugin-project-management-react';

const useStyles = makeStyles({
  root: {
    width: '90%',
  },
  tableLabel: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
    borderBottom: '1px solid lightgrey',
  },
});

const ReportsTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const saveFormDataAsString = localStorage.getItem('formData');
  const savedFormData =
    saveFormDataAsString !== null
      ? JSON.parse(saveFormDataAsString)
      : {
          projectName: '',
          projectType: '',
          customerName: '',
        };

  const {
    services: { reportsService },
  } = useContext(AppContext);
  useEffect(() => {
    setPage(0);
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { projectName, projectType, customerName } = savedFormData;
        const resultData = await reportsService.getReports(projectName, projectType, customerName);
        setData(resultData);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching projects:', error);
        setMessage('An error occurred trying to get the current projects');
        setStatus('error');
        setOpen(true);
      }
    };
    fetchData();
  }, []);

  const rowData: TableRows[] = data.map(row => {
    const { projectName, projectType, customerName, owners, numberOfComponents, languages } = row;
    return createData(projectName, projectType, customerName, owners, numberOfComponents, languages);
  });

  const rows: TableRows[] = rowData;

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if (isLoading) {
    return <Progress />;
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box>
        <FilterTable
          onLoading={load => setIsLoading(load)}
          onFilterSubmit={result => setData(result)}
          // ToDO fixme
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onMessage={message => setMessage(message)}
          // ToDO fixme
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onStatus={status => setStatus(status)}
          // ToDO fixme
          /* eslint-disable-next-line @typescript-eslint/no-shadow */
          onOpen={open => setOpen(open)}
        />
      </Box>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth }}
                    className={classes.tableLabel}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(row => {
                return (
                  <TableRow hover role="checkbox" key={row.projectName}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          component="th"
                          style={{ width: 160 }}
                          scope="row"
                          align={column.id === 'numberOfComponents' ? 'right' : 'left'}
                          key={column.id}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Paper>
    </Box>
  );
};

export default ReportsTable;
