import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import { ConfluenceItem } from '@softwareone/plugin-project-management-react/src/components/features/confluence/ConfluenceItem';

export type ChangeDataConfluenceSpace = {
  confluenceAdministrators: string[];
  confluenceContributors: string[];
  confluenceCustomers: string[];
  confluenceSpaceKey?: string;
  visibleToEveryone?: boolean;
};

type UserResponse = {
  accountId: string;
  publicName: string;
};

type CreateConfluenceProps = {
  onChange: (e: ChangeDataConfluenceSpace) => void;
  setIsValid: (e: boolean) => void;
  isValid: boolean;
  confluenceProjectName: string;
  displayProjectKeyConfluece: string;
  confluenceUsersValue: UserResponse[];
  createConfluenceForm: ChangeDataConfluenceSpace;
  isChecked: boolean;
  setIsChecked: (e: boolean) => void;
  getTeamMembersForConfluence: () => void;
};

export const CreateConfluenceSpace = ({
  confluenceProjectName,
  onChange,
  setIsValid,
  isValid,
  displayProjectKeyConfluece,
  confluenceUsersValue,
  createConfluenceForm,
  isChecked = false,
  setIsChecked,
  getTeamMembersForConfluence,
}: CreateConfluenceProps) => {
  const jiraProjectKey = false;
  const [users, setUsers] = useState<string[]>([]);

  const handleValidityChange = (e: boolean) => {
    setIsValid(e);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (!isChecked) {
      setIsValid(true);
      return;
    }
    setIsValid(isValid);
  }, [isChecked]);

  useEffect(() => {
    if (!confluenceUsersValue) return;
    setUsers((confluenceUsersValue as { publicName: string }[]).map(val => val.publicName));
    setIsValid(true);
  }, [confluenceUsersValue]);

  const handleChange = (e: ChangeDataConfluenceSpace) => {
    onChange({
      confluenceSpaceKey: e.confluenceSpaceKey,
      confluenceAdministrators: e.confluenceAdministrators,
      confluenceContributors: e.confluenceContributors,
      confluenceCustomers: e.confluenceCustomers,
      visibleToEveryone: e.visibleToEveryone,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mr: 4,
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={handleChecked} size="medium" checked={isChecked} />}
          label="Create Confluence Space"
          style={{
            marginLeft: '0.5rem',
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        />
      </FormGroup>
      <ConfluenceItem
        confluenceSpaceName={confluenceProjectName}
        confluenceSpaceKey={createConfluenceForm.confluenceSpaceKey ?? displayProjectKeyConfluece}
        confluenceAdministrators={createConfluenceForm.confluenceAdministrators}
        confluenceContributors={createConfluenceForm.confluenceContributors}
        confluenceCustomers={createConfluenceForm.confluenceCustomers}
        withSharedJiraProject={!!jiraProjectKey}
        visibleToEveryone={createConfluenceForm.visibleToEveryone}
        onChange={handleChange}
        onValidityChange={handleValidityChange}
        isDisabled={!isChecked}
        userList={users}
        getTeamMembersForConfluence={getTeamMembersForConfluence}
      />
    </Box>
  );
};
