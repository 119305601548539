import React from 'react';
import { Box } from '@material-ui/core';
import { TableProjectInfo } from './tables/TableProjectInfo';
import { ChangeDataProjectInfoType } from '../components/CreateProjectInfo';
import { ChangeDataTeam } from '../components/CreateTeam';
import { ChangeDataJiraProject } from '../components/CreateJiraProject';
import { ChangeDataConfluenceSpace } from '../components/CreateConfluenceSpace';
import { TableTeam } from './tables/TableTeam';
import { TableJiraProject } from './tables/TableJiraProject';
import { TableConfluenceSpace } from './tables/TableConfluenceSpace';
import { TableGitHubRepo } from './tables/TableGitHubRepo';
import { ChangeDataGitHubRepo } from './CreateGithubRepo';

interface ConfirmProjectCreationProps {
  projectInfoData: {
    createProjectInfoForm: ChangeDataProjectInfoType;
    urls: { url: string; title: string }[];
  };
  teamData: {
    createTeamForm: ChangeDataTeam | undefined;
  };
  jiraProjectData: {
    createJiraProjectForm: ChangeDataJiraProject | undefined;
  };
  confluenceSpaceData: {
    createConfluenceForm: ChangeDataConfluenceSpace | undefined;
  };
  gitHubRepoData: {
    createGitHubRepoForm: ChangeDataGitHubRepo | undefined;
  };
}

export const ConfirmProjectCreation: React.FC<ConfirmProjectCreationProps> = ({
  projectInfoData,
  teamData,
  jiraProjectData,
  confluenceSpaceData,
  gitHubRepoData,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 4,
        mb: 4,
      }}
    >
      <TableProjectInfo createProjectInfoForm={projectInfoData.createProjectInfoForm} urls={projectInfoData.urls} />
      {teamData.createTeamForm && <TableTeam createTeamForm={teamData.createTeamForm} />}
      {jiraProjectData.createJiraProjectForm && (
        <TableJiraProject createJiraProjectForm={jiraProjectData.createJiraProjectForm} />
      )}
      {confluenceSpaceData.createConfluenceForm && (
        <TableConfluenceSpace createConfluenceForm={confluenceSpaceData.createConfluenceForm} />
      )}
      {gitHubRepoData.createGitHubRepoForm && (
        <TableGitHubRepo createGitHubRepoForm={gitHubRepoData.createGitHubRepoForm} />
      )}
    </Box>
  );
};
