import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { ProjectInfoSelect } from './features/projectInfo/ProjectInfoItem';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { CatalogFilters } from '../types';

export const disabledFieldSchema = makeFieldSchemaFromZod(
  z.boolean(),
  z.object({
    disabledIf: z
      .array(
        z.object({
          formDataProperty: z.string(),
          equals: z.string().optional(),
          in: z.array(z.string()).optional(),
          defaultValue: z.any().optional(),
        })
      )
      .optional()
      .describe('Whether to disable this field'),
  })
);

export const selectOptionsFieldSchema = makeFieldSchemaFromZod(
  z.boolean(),
  z.object({
    selectOptions: z
      .object({
        apiPath: z.string(),
        labelKey: z.string().optional(),
        valueKey: z.string().optional(),
        placeholder: z.string().optional(),
        isMulti: z.boolean().optional(),
        hiddenIf: z
          .array(
            z.object({
              formDataProperty: z.string(),
              equals: z.string().optional(),
              in: z.array(z.string()).optional(),
            })
          )
          .optional()
          .describe('Whether to hide this field'),
        styles: z
          .array(
            z.object({
              property: z.string(),
              value: z.string(),
            })
          )
          .optional(),
      })
      .optional()
      .describe('Configuration for abstract select options through API Path fetch.'),
  })
);

export const getFieldValidationRules = (projectType: string, userSelect: ProjectInfoSelect) => {
  const technologySchema = z.object({
    id: z.string(),
    type: z.string(),
    name: z.string(),
  });
  const industrySchema = z.object({
    'SIC Code': z.number(),
    Office: z.string(),
    'Industry Title': z.string(),
  });

  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
  let fieldValidationRules;
  switch (projectType) {
    case 'Customer':
      fieldValidationRules = z
        .object({
          projectType: z.string(),
          oneSalesID: z.string().refine(value => /^(OPP)-[12]\d{3}-\d{6}(-\d+)?$/.test(value), {
            message: 'Invalid OneSales ID format',
          }),
          psaProjectId: z.string(),
          erpProjectNumber: z.string(),
          projectCustomer: z.string().refine(value => value.length >= 1 && value.length <= 16, {
            message: 'Customer name must be between 1 and 16 characters',
          }),
          projectTitle: z.string().refine(value => value.length >= 1 && value.length <= 500, {
            message: 'Project Title must be between 1 to 500 characters',
          }),
          projectKeyState: z
            .string()
            .refine(value => value.length >= 1 && value.length <= 16, {
              message: 'Project Key must be between 1 and 16 characters',
            })
            .refine(value => value !== '', {
              message: 'Project Key must not be empty',
            }),
          projectDescription: z.string(),
          technologies: z.array(technologySchema).nonempty(),
          industries: z.array(industrySchema),
          url: z
            .string()
            .optional()
            .refine(value => value === undefined || value === '' || urlPattern.test(value), {
              message: 'Invalid URL format',
            }),
        })
        .superRefine((arg, ctx) => {
          const { erpProjectNumber, psaProjectId } = arg;
          const psaProjectIdCheck = /^[A-Z]{2,3}-(PRJ|OPP)-\d{6}$/.test(psaProjectId);
          const erpProjectNumberCheck = /^[A-Z]{2}-PRJ-\d{6}$/.test(erpProjectNumber);

          if ((erpProjectNumber && psaProjectId) || (!erpProjectNumber && !psaProjectId)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Either PSA ID or ERP Project Number should be filled',
              path: ['erpProjectNumber', 'psaProjectId'],
            });
            return;
          }

          if (!erpProjectNumber && !psaProjectIdCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid PSA Project ID format',
              path: ['psaProjectId'],
            });
            return;
          }

          if (!psaProjectId && !erpProjectNumberCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid ERP Project ID format',
              path: ['erpProjectNumber'],
            });
          }
        });
      break;
    case 'Internal':
      fieldValidationRules = z
        .object({
          projectType: z.string(),
          oneSalesID: z.string().refine(value => /^$|^(OPP)-[12]\d{3}-\d{6}(-\d+)?$/.test(value), {
            message: 'Format must match the above format or be empty',
          }),
          psaProjectId: z.string(),
          erpProjectNumber: z.string(),
          projectCustomer: z.string().refine(value => value === 'swo', {
            message: 'Customer must be "swo"',
          }),
          projectTitle: z.string().refine(value => value.length >= 1 && value.length <= 500, {
            message: 'Project Title must be between from 1 to 500 characters',
          }),
          projectKeyState: z
            .string()
            .refine(value => value.length >= 1 && value.length <= 16, {
              message: 'Project Key must be between 1 and 16 characters',
            })
            .refine(value => value !== '', {
              message: 'Project Key must not be empty',
            }),
          projectDescription: z.string(),
          technologies: z.array(technologySchema).nonempty(),
          industries: z.array(industrySchema),
          url: z
            .string()
            .optional()
            .refine(value => value === undefined || value === '' || urlPattern.test(value), {
              message: 'Invalid URL format',
            }),
        })
        .superRefine((arg, ctx) => {
          const { erpProjectNumber, psaProjectId } = arg;
          const psaProjectIdCheck = /^[A-Z]{2,3}-(PRJ|OPP)-\d{6}$/.test(psaProjectId);
          const erpProjectNumberCheck = /^[A-Z]{2}-PRJ-\d{6}$/.test(erpProjectNumber);
          if (!erpProjectNumber && !psaProjectId) {
            return;
          }

          if (erpProjectNumber && psaProjectId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Either PSA ID or ERP Project Number should be filled',
              path: ['erpProjectNumber', 'psaProjectId'],
            });
            return;
          }

          if (!erpProjectNumber && !psaProjectIdCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid PSA Project ID format',
              path: ['psaProjectId'],
            });
            return;
          }

          if (!psaProjectId && !erpProjectNumberCheck) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid ERP Project ID format',
              path: ['erpProjectNumber'],
            });
          }
        });
      break;
    case 'Personal':
      fieldValidationRules = z.object({
        projectType: z.string(),
        oneSalesID: z.string().refine(value => value === '', {
          message: 'OneSales ID must be empty',
        }),
        psaProjectId: z.string().refine(value => value === '', {
          message: 'PSA Project ID must be empty',
        }),
        erpProjectNumber: z.string().refine(value => value === '', {
          message: 'ERP Project Number must be empty',
        }),
        projectCustomer: z.string().refine(value => value === 'personal', {
          message: 'Customer must be "personal"',
        }),
        projectTitle: z.string().refine(value => value.length >= 1 && value.length <= 500, {
          message: 'Project Title must be between from 1 to 500 characters',
        }),
        projectKeyState: z
          .string()
          .refine(value => value.length >= 1 && value.length <= 16, {
            message: 'Project Key must be between 1 and 16 characters',
          })
          .refine(value => value !== '', {
            message: 'Project Key must not be empty',
          }),
        projectDescription: z.string(),
        technologies: z.array(technologySchema).nonempty(),
        industries: z.array(industrySchema),
        url: z
          .string()
          .optional()
          .refine(value => value === undefined || value === '' || urlPattern.test(value), {
            message: 'Invalid URL format',
          }),
      });
      break;
    default:
      throw new Error('Invalid projectType');
  }
  return fieldValidationRules.parse(userSelect);
};

const MultiValueFieldSchema = selectOptionsFieldSchema;
type MultiValueFieldProps = typeof MultiValueFieldSchema.type;

export const processHiddenConditions = (props: MultiValueFieldProps) => {
  const hiddenIf = (props.uiSchema['ui:options']?.hiddenIf as any[]) || [];
  let hidden = false;

  hiddenIf.forEach(x => {
    let shouldHide = false;
    const formDataPropertyValue = props.formContext.formData[x.formDataProperty];
    if (x.equals) {
      shouldHide = formDataPropertyValue === x.equals;
    }
    if (x.in) {
      shouldHide = shouldHide || x.in.includes(formDataPropertyValue);
      if (formDataPropertyValue === undefined) {
        shouldHide = !shouldHide;
      }
    }
    hidden = hidden || shouldHide;
  });

  return hidden;
};

export const reduceCatalogFilters = (filters: EntityFilter[]): CatalogFilters => {
  const condensedFilters = filters.reduce<CatalogFilters['filter']>((compoundFilter, filter) => {
    return {
      ...compoundFilter,
      ...(filter.getCatalogFilters ? filter.getCatalogFilters() : {}),
    };
  }, {});
  return { filter: condensedFilters };
};
