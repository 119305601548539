import React, { useState, useEffect, useContext } from 'react';
import {
  TextFieldFormInput,
  AlertSnackbar,
  SelectFormInput,
  AppContext,
} from '@softwareone/plugin-project-management-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEntity } from '@backstage/plugin-catalog-react';
import { z, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, FormHelperText, Paper, TextField } from '@material-ui/core';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import Stack from '@mui/material/Stack';
import { Progress } from '@backstage/core-components';
import { useUserProfile } from '@backstage/plugin-user-settings';
import {
  checkUserOwnership,
  extractGroupAdminInfo,
  extractSonarValues,
  UserObject,
  SonarInstance,
} from '../utils/utils';
import { SystemEntity } from '@softwareone/plugin-project-management-common';
import {
  FlexContainer,
  InfoToolTip,
} from '@softwareone/plugin-project-management-react/src/components/infoToolTip/InfoToolTip';

export const ComponentInfoCard = () => {
  const { entity } = useEntity();
  const { name: currentComponentName, creatorActiveDirectoryId } = entity.metadata as SystemEntity;
  const { system = '', lifecycle = '', type = '' } = entity.spec || {};
  const kind = entity.kind || '';
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [sonarInstances, setSonarInstances] = useState<SonarInstance[]>([]);
  const gitHubApi = useApi(githubAuthApiRef);
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const {
    services: { componentService, projectService },
  } = useContext(AppContext);

  const kindOptions = [
    { label: 'Component', value: 'Component' },
    { label: 'API', value: 'API' },
  ];

  const typeOptions = [
    { label: 'Service', value: 'Service' },
    { label: 'Website', value: 'Website' },
    { label: 'Library', value: 'Library' },
    { label: 'Web Application', value: 'Web Application' },
    { label: 'Mobile Application', value: 'Mobile Application' },
    { label: 'Business Intelligence', value: 'Business Intelligence' },
    { label: 'Machine Learning', value: 'Machine Learning' },
    { label: 'AI', value: 'AI' },
    { label: 'Function', value: 'Function' },
    { label: 'Low Code', value: 'Low Code' },
    { label: 'CMS', value: 'CMS' },
    { label: 'CRM', value: 'CRM' },
    { label: 'CLI', value: 'CLI' },
    { label: 'Infrastructure as Code', value: 'Infrastructure as Code' },
    { label: 'Automated Testing', value: 'Automated Testing' },
    { label: 'Documentation', value: 'Documentation' },
    { label: 'Other', value: 'Other' },
  ];

  const sonarInstancesOptions = [
    { label: '\u00A0', value: '\u00A0' },
    ...sonarInstances.map(instance => ({
      label: instance.name,
      value: instance.name,
    })),
  ];

  const lifecycleOptions = [
    { label: 'experimental', value: 'experimental' },
    { label: 'production', value: 'production' },
    { label: 'deprecated', value: 'deprecated' },
  ];

  const formSchema = z
    .object({
      kind: z.enum(['Component', 'API'] as const),
      type: z.enum([
        'Service',
        'Website',
        'Library',
        'Web Application',
        'Mobile Application',
        'Business Intelligence',
        'Machine Learning',
        'AI',
        'Function',
        'Low Code',
        'CMS',
        'CRM',
        'CLI',
        'Infrastructure as Code',
        'Automated Testing',
      ] as const),
      lifecycle: z.enum(['experimental', 'production', 'deprecated'] as const),
      suffix: z.string().max(32, { message: "Suffix can't exceed 32 characters" }),
      extractComponentName: z.string().min(1, { message: "Component Name can't be empty" }).max(32, {
        message: "Component Name can't exceed 32 characters",
      }),
      projectKeySonar: z.string().optional(),
      selectedSonarInstance: z.string().optional(),
    })
    .refine(
      data => {
        if (
          data.projectKeySonar !== '' &&
          data.projectKeySonar !== undefined &&
          data.selectedSonarInstance === '\u00A0'
        ) {
          return false;
        }

        return true;
      },
      {
        message: 'Please add sonar instance when sonar project key is added ',
        path: ['selectedSonarInstance'],
      }
    )
    .refine(
      data => {
        if (data.selectedSonarInstance && data.selectedSonarInstance !== '\u00A0' && !data.projectKeySonar) {
          return false;
        }
        return true;
      },
      {
        message: 'Please add sonar project key when sonar instance is selected ',
        path: ['projectKeySonar'],
      }
    );

  type FormInput = TypeOf<typeof formSchema>;

  const splitComponentName = currentComponentName.split('-');
  const extractComponentName = splitComponentName[2];
  const extractSuffix = splitComponentName.length >= 4 ? splitComponentName[3] : '';

  const extractedComponentTechnologies: string[] = (entity?.metadata['technologies'] || []) as string[];

  const sonarAnnotationsValue = entity.metadata?.annotations?.['sonarqube.org/project-key'];
  const { extractSonarInstance, extractProjectKeySonar } = extractSonarValues(sonarAnnotationsValue, sonarInstances);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await componentService.getSonarInstances();
        const transformedInstances: SonarInstance[] = response.sonarqube.instances.map((instance: any) => ({
          name: instance,
        }));
        setSonarInstances(transformedInstances);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      kind: kind as FormInput['kind'],
      type: type as FormInput['type'],
      lifecycle: lifecycle as FormInput['lifecycle'],
      extractComponentName: extractComponentName,
      suffix: extractSuffix,
      projectKeySonar: extractProjectKeySonar,
      selectedSonarInstance:
        typeof extractSonarInstance === 'string' ? extractSonarInstance : extractSonarInstance?.name ?? '\u00A0',
    },
  });

  useEffect(() => {
    if (typeof extractSonarInstance === 'string') {
      setValue('selectedSonarInstance', extractSonarInstance);
    }
  }, [extractSonarInstance]);

  const { projectKeySonar, selectedSonarInstance } = watch();

  useEffect(() => {
    trigger('projectKeySonar');
    trigger('selectedSonarInstance');
  }, [projectKeySonar, selectedSonarInstance]);

  const hasSuffixError = errors !== null && errors.hasOwnProperty('suffix');
  const hasComponentNameError = errors !== null && errors.hasOwnProperty('extractComponentName');
  const hasSonarInstanceError = errors !== null && errors.hasOwnProperty('selectedSonarInstance');
  const hasSonarProjectKeyError = errors !== null && errors.hasOwnProperty('projectKeySonar');

  const deactivateSave = () => {
    if (hasSuffixError || hasComponentNameError || hasSonarInstanceError || hasSonarProjectKeyError) {
      return true;
    }
    return false;
  };

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    if (typeof system !== 'string' || !profile) return;
    const isUserOwner = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        system,
        formattedProfile,
        hasGithubAdmins,
        setIsOwner,
        creatorActiveDirectoryId
      );
    };
    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    reset();
    setSubmitLoading(true);
    const sonarInstanceName = data.selectedSonarInstance === '\u00A0' ? '' : data.selectedSonarInstance;
    const sonarProjectKey = data.projectKeySonar ?? '';
    const request = {
      projectKey: system as string,
      componentKey: currentComponentName,
      componentName: data.extractComponentName,
      suffix: data.suffix,
      kind: data.kind,
      type: data.type,
      lifecycle: data.lifecycle,
      sonarInstanceName: sonarInstanceName,
      sonarProjectKey: sonarProjectKey,
    };
    try {
      await componentService.updateComponent(request);
      setMessage('Component Info is successfully updated');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      let errorMessage = `An unknown error occurred during the API info update`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if (submitLoading) {
    return <Progress />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Paper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ m: 3 }}>
            <FlexContainer>
              <SelectFormInput label="Kind" name="kind" options={kindOptions} control={control} disabled={!isOwner} />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="Determines if the component will be a regular component or an API component. Both entity types represent physical project components and are treated equally. API components are more suitable for project components which expose APIs used by other components. API entities are designed to contain the OpenAPI definition of the exposed API." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <TextFieldFormInput
                name="extractComponentName"
                control={control}
                label="Component Name"
                disabled={!isOwner}
              />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip
                  text={
                    'The base name of the component (e.g., “Salary Calculator”) that will be used in the internal identifier of the component and the name of its corresponding GitHub repository. The entered text will be converted to lowercase and all spaces will be replaced with underscores (“_”).\nThe GitHub repository name will be <customer>-<project key>-<component name>-<suffix>.\nValid format: any text containing at least 1 and at most 32 characters.'
                  }
                />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <TextFieldFormInput name="suffix" control={control} label="Suffix" disabled={!isOwner} />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip
                  text={
                    'An optional suffix for extra information added after the name (e.g., “service”, “java”, “angular”, “script”). The entered text will be converted to lowercase and all spaces will be replaced with underscores (“_”).\nThe GitHub repository name will be <customer>-<project key>-<component name>-<suffix>.\nValid format: any text containing at most 32 characters.'
                  }
                />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <SelectFormInput label="Type" name="type" options={typeOptions} control={control} disabled={!isOwner} />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="The purpose the component will serve." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <SelectFormInput
                label="Lifecycle"
                name="lifecycle"
                options={lifecycleOptions}
                control={control}
                disabled={!isOwner}
              />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="Choose what stage of its lifecycle the component is at." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                <SelectFormInput
                  label="Sonar Instance"
                  name="selectedSonarInstance"
                  options={sonarInstancesOptions}
                  control={control}
                  disabled={!isOwner}
                />
                {errors.selectedSonarInstance && (
                  <span
                    style={{
                      color: 'red',
                      paddingLeft: '1rem',
                      paddingTop: '0.5rem',
                      fontSize: '0.8rem',
                    }}
                  >
                    {errors.selectedSonarInstance.message}
                  </span>
                )}
              </div>
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="The Sonar instance where the source code of the component is scanned. If a Sonar instance is selected, then a valid Sonar project key must be entered as well" />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <TextFieldFormInput
                name="projectKeySonar"
                control={control}
                label="Sonar Project Key"
                disabled={!isOwner}
              />
              <Box sx={{ ml: 2 }}>
                <InfoToolTip text="The key of the project in the selected Sonar instance, responsible for scanning the source code of the component. If a Sonar instance is selected, then a valid Sonar project key must be entered as well." />
              </Box>
            </FlexContainer>
            <Box style={{ width: '100%', paddingRight: '20' }}>
              <TextField
                label={'Technologies'}
                variant={'outlined'}
                disabled
                multiline
                fullWidth
                value={extractedComponentTechnologies.join(',  ')}
                style={{ paddingRight: 40 }}
              />
              <FormHelperText style={{ color: 'darkgray' }}>
                *Technologies are updated automatically from the GitHub repository of the component at a certain period
              </FormHelperText>
            </Box>
            <Button variant="outlined" fullWidth type="submit" disabled={!isOwner || deactivateSave()}>
              Save
            </Button>
          </Stack>
        </form>
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Paper>
    </Box>
  );
};
