import React from 'react';
import { Content, ContentHeader, PageWithHeader } from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import ReportsTable from '../components/table/reportsTable/ReportsTable';

export function ReportsPage() {
  return (
    <PageWithHeader title="Reports" themeId="home">
      <Content>
        <ContentHeader title="" />
        <EntityListProvider>
          <ReportsTable />
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}
