import React, { useState, useEffect, useContext, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { Button, Step, Stepper, StepLabel } from '@mui/material';
import { Progress } from '@backstage/core-components';
import { CreateProjectInfo, ChangeDataProjectInfoType } from '../components/CreateProjectInfo';
import { CreateTeam, ChangeDataTeam } from '../components/CreateTeam';
import { CreateJiraProject, ChangeDataJiraProject } from '../components/CreateJiraProject';
import { CreateConfluenceSpace, ChangeDataConfluenceSpace } from '../components/CreateConfluenceSpace';
import { CreateGithubRepo, ChangeDataGitHubRepo } from '../components/CreateGithubRepo';
import { ConfirmProjectCreation } from '../components/ConfirmProjectCreation';
import { useOrganizationTeamMembers } from '../../hook/useOrganizationTeamMembers';
import { useOrganizationJiraTeamMembers } from '../../hook/useOrganizationJiraTeamMembers';
import { useConfluenceOrganizationUsers } from '../../hook/useConfluenceOrganizationUsers';
import { useAdditionalInfo } from '../../hook/useAdditionalInfo';
import { Industry, Technology, UserModel } from '@softwareone/plugin-project-management-common';
import { AppContext, AlertSnackbar } from '@softwareone/plugin-project-management-react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { SuccessProjectCreation } from '../components/SuccessProjectCreation';
import { CreateProjectInput } from '@softwareone/plugin-project-management-common/src/types/project-management/input/createProjectInput';
import { getTeamMembers, handleSetUrls } from '../../utils/utils';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

type UserResponse = {
  accountId: string;
  publicName: string;
};

const CreateProjectTable = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<'success' | 'error'>('error');
  const [message, setMessage] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isValidJiraForm, setIsValidJiraForm] = useState<boolean>(false);
  const [isValidConfluenceForm, setIsValidConfluenceForm] = useState<boolean>(false);
  const [isValidGitHubRepo, setIsValidGitHubRepo] = useState<boolean>(false);
  const [isSuccessfulProjectCreation, setIsSuccessfulProjectCreation] = useState<boolean>(false);
  const [createJiraProject, setCreateJiraProject] = useState<boolean>(true);
  const [createConfluenceSpace, setCreateConfluenceSpace] = useState<boolean>(true);
  const [createGitHubRepo, setCreateGitHubRepo] = useState<boolean>(true);
  const [confirmData, setConfirmData] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [urlTitleError, setUrlTitleError] = useState<boolean>(false);

  const {
    services: { projectService, gitHubService },
  } = useContext(AppContext);
  const userProfile = useUserProfile().displayName;

  const [createProjectInfoForm, setCreateProjectInfoForm] = useState<ChangeDataProjectInfoType>({
    projectType: 'Customer',
    oneSalesID: '',
    psaProjectId: '',
    erpProjectNumber: '',
    projectCustomer: '',
    projectTitle: '',
    projectKeyState: '',
    projectDescription: '',
    url: '',
    urlTitle: '',
    technologies: [],
    industries: [],
  });
  const [templateOptionsLoading, setTemplateOptionsLoading] = useState<boolean>(false);

  const [urls, setUrls] = useState<{ url: string; title: string }[]>([]);
  const [createTeamForm, setCreateTeamForm] = useState<ChangeDataTeam>({
    gitHubOwners: [],
    gitHubContributors: [],
    gitHubReaders: [],
    everyoneAtSWOCanRead: true,
  });

  const [createJiraProjectForm, setCreateJiraProjectForm] = useState<ChangeDataJiraProject>({
    jiraProjectTemplate: '',
    jiraProjectKey: '',
    jiraProjectLeadName: '',
    jiraContributors: [],
    jiraCustomers: [],
    jiraAdministrators: [],
    visibleForEveryone: true,
  });

  const [createConfluenceForm, setCreateConfluenceForm] = useState<ChangeDataConfluenceSpace>({
    confluenceAdministrators: [],
    confluenceContributors: [],
    confluenceCustomers: [],
    confluenceSpaceKey: '',
    visibleToEveryone: true,
  });
  const [createGitHubRepoForm, setCreateGitHubRepoForm] = useState<ChangeDataGitHubRepo>({
    kind: '',
    type: '',
    lifecycle: '',
    contentType: '',
    componentName: '',
    suffix: '',
    isFormValid: false,
  });
  const [teamMembersValue, setTeamMembersValue] = useState<{ username: string; email: string }[]>([]);

  const [jiraTeamMembersValue, setJiraTeamMembersValue] = useState<UserModel[]>([]);

  const [confluenceUsersValue, setConfluenceUsersValue] = useState<UserResponse[]>([]);
  const [templateOptionsGitHubRepo, setTemplateOptionsGitHubRepo] = useState<string[]>([]);
  const [additionalInfoOptionsValue, setAdditionalInfoOptionsValue] = useState<{
    technologies: Technology[];
    industries: Industry[];
  }>({ technologies: [], industries: [] });
  const alertApi = useApi(alertApiRef);
  const { loading: membersLoading, value: teamMembers } = useOrganizationTeamMembers();
  const { value: jiraTeamMembers } = useOrganizationJiraTeamMembers();
  const { value: confluenceUsers } = useConfluenceOrganizationUsers();
  const { value: additionalInfoOptions } = useAdditionalInfo();

  const getTemplateOptions = useCallback(
    async (signal: AbortSignal) => {
      if (createGitHubRepoForm.contentType === 'From Template' && !templateOptionsGitHubRepo.length) {
        try {
          setTemplateOptionsLoading(true);
          const data = await gitHubService.getTemplates(signal);
          setTemplateOptionsGitHubRepo(data);
          setTemplateOptionsLoading(false);
        } catch (err) {
          let errorMessage = 'An error occured during server request';
          if (err instanceof Error) {
            if (err.name === 'AbortError') {
              return;
            }
            errorMessage = err.message;
          }
          setTemplateOptionsLoading(false);
          alertApi.post({ message: errorMessage, severity: 'error' });
        }
      }
    },
    [createGitHubRepoForm.contentType]
  );

  useEffect(() => {
    const controller = new AbortController();
    getTemplateOptions(controller.signal);
    return () => controller?.abort();
  }, [getTemplateOptions]);

  const handleUrlAddition = () => {
    const success = handleSetUrls(createProjectInfoForm.url, createProjectInfoForm.urlTitle, setUrls, setUrlTitleError);
    if (success) {
      createProjectInfoForm.url = '';
      createProjectInfoForm.urlTitle = '';
    }
  };

  const deleteUrl = (indexToDelete: number) => {
    const updatedUrls = [...urls];
    updatedUrls.splice(indexToDelete, 1);
    setUrls(updatedUrls);
  };

  const getTeamMembersForJira = () => {
    const updatedFilteredMembers = getTeamMembers(createTeamForm, teamMembersValue, jiraTeamMembersValue);
    setCreateJiraProjectForm(prevState => ({
      ...prevState,
      jiraAdministrators: updatedFilteredMembers.owners,
      jiraContributors: updatedFilteredMembers.contributors,
      jiraCustomers: updatedFilteredMembers.readers,
    }));
  };

  const getTeamMembersForConfluence = () => {
    const updatedFilteredMembers = getTeamMembers(createTeamForm, teamMembersValue, jiraTeamMembersValue);
    setCreateConfluenceForm(prevState => ({
      ...prevState,
      confluenceAdministrators: updatedFilteredMembers.owners,
      confluenceContributors: updatedFilteredMembers.contributors,
      confluenceCustomers: updatedFilteredMembers.readers,
    }));
  };

  useEffect(() => {
    if (teamMembers && jiraTeamMembers && confluenceUsers && additionalInfoOptions) {
      setTeamMembersValue(teamMembers);
      setJiraTeamMembersValue(jiraTeamMembers);
      setConfluenceUsersValue(confluenceUsers);
      setAdditionalInfoOptionsValue(additionalInfoOptions);
    }
  }, [teamMembers, jiraTeamMembers, confluenceUsers]);

  const steps = ['Project Info', 'Team', 'Jira Project', 'Confluence Space', 'GitHub Repo', 'Confirm'];
  const formatUsersConfluence = () => {
    return {
      administrators: confluenceUsersValue
        .filter(user => createConfluenceForm.confluenceAdministrators.includes(user.publicName))
        .map(user => user.accountId),
      contributors: confluenceUsersValue
        .filter(user => createConfluenceForm.confluenceContributors.includes(user.publicName))
        .map(user => user.accountId),
      customers: confluenceUsersValue
        .filter(user => createConfluenceForm.confluenceCustomers.includes(user.publicName))
        .map(user => user.accountId),
    };
  };

  const formatTeamMembersJira = () => {
    return {
      projectLead: jiraTeamMembersValue
        .filter(user => createJiraProjectForm.jiraProjectLeadName === user.displayName)
        .map(user => user.accountId),
      administrators: jiraTeamMembersValue
        .filter(user => createJiraProjectForm.jiraAdministrators.includes(user.displayName as string))
        .map(user => user.accountId),
      contributors: jiraTeamMembersValue
        .filter(user => createJiraProjectForm.jiraContributors.includes(user.displayName as string))
        .map(user => user.accountId),
      customers: jiraTeamMembersValue
        .filter(user => createJiraProjectForm.jiraCustomers.includes(user.displayName as string))
        .map(user => user.accountId),
    };
  };

  const generateJiraProjectName = () =>
    `${createProjectInfoForm.projectCustomer}-${createProjectInfoForm.projectTitle}`;

  const generateConfluenceProjectName = () =>
    `${createProjectInfoForm.projectCustomer}-${createProjectInfoForm.projectTitle}`;

  const getConfluenceProjectKey = () => {
    if (createJiraProjectForm && createJiraProjectForm.jiraProjectKey !== '') {
      return `${createJiraProjectForm.jiraProjectKey}`;
    }
    return '';
  };

  const getJiraProjectKey = () => {
    if (createConfluenceForm && createConfluenceForm.confluenceSpaceKey !== '') {
      return `${createConfluenceForm.confluenceSpaceKey}`;
    }
    return '';
  };

  const handleChangeProjectInfo = (e: ChangeDataProjectInfoType) => {
    setCreateProjectInfoForm(e);
  };

  const handleChangeTeam = (e: ChangeDataTeam) => {
    setCreateTeamForm(e);
  };

  const handleChangeJiraProject = (e: ChangeDataJiraProject) => {
    setCreateJiraProjectForm(e);
    if (createConfluenceForm) {
      setCreateConfluenceForm({
        ...createConfluenceForm,
        confluenceSpaceKey: e.jiraProjectKey,
      });
    }
  };

  const handleChangeConfluenceProject = (e: ChangeDataConfluenceSpace) => {
    setCreateConfluenceForm(e);
    if (createJiraProjectForm) {
      setCreateJiraProjectForm({
        ...createJiraProjectForm,
        jiraProjectKey: e.confluenceSpaceKey,
      });
    }
  };

  const handleStepClick = (step: number) => {
    if (step < activeStep) {
      setActiveStep(step);
      setConfirmData(false);
    }
  };

  const handleChangeGitHubRepo = (e: ChangeDataGitHubRepo) => {
    setCreateGitHubRepoForm(e);
  };

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
    setConfirmData(false);
  };
  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };
  const handleConfirm = () => {
    setConfirmData(true);
    setActiveStep(prevStep => prevStep + 1);
  };

  const buildCreateRequest = () => {
    const jiraMembers = formatTeamMembersJira();
    const FormattedConfluenceUsers = formatUsersConfluence();

    const request: CreateProjectInput = {
      projectInfoInput: {
        projectType: createProjectInfoForm.projectType,
        oneSalesId: createProjectInfoForm.oneSalesID,
        psaId: createProjectInfoForm.psaProjectId,
        erpProjectNumber: createProjectInfoForm.erpProjectNumber,
        customerName: createProjectInfoForm.projectCustomer,
        projectTitle: createProjectInfoForm.projectTitle,
        unformattedProjectKey: createProjectInfoForm.projectKeyState,
        projectDescription: createProjectInfoForm.projectDescription,
        technologies: createProjectInfoForm.technologies.map(tech => tech.id),
        industries: createProjectInfoForm.industries.map(industry => industry['SIC Code']),
      },
      linksInput: urls,
      teamInput: {
        gitHubOwners: createTeamForm.gitHubOwners,
        gitHubContributors: createTeamForm.gitHubContributors,
        gitHubReaders: createTeamForm.gitHubReaders,
        everyoneAtSwoCanReadFlag: createTeamForm.everyoneAtSWOCanRead,
      },
      jiraInput: {
        createJiraProject: createJiraProject,
        jiraProjectLead: jiraMembers.projectLead[0],
        jiraProjectKey: createJiraProjectForm.jiraProjectKey,
        jiraProjectCreationTemplate: createJiraProjectForm.jiraProjectTemplate,
        jiraAdministrators: jiraMembers.administrators,
        jiraContributors: jiraMembers.contributors,
        jiraCustomers: jiraMembers.customers,
        visibleForEveryone: createJiraProjectForm.visibleForEveryone as boolean,
      },
      confluenceInput: {
        createConfluenceSpace: createConfluenceSpace,
        confluenceSpaceKey: createConfluenceForm.confluenceSpaceKey,
        confluenceContributors: FormattedConfluenceUsers.contributors,
        confluenceCustomers: FormattedConfluenceUsers.customers,
        confluenceAdministrators: FormattedConfluenceUsers.administrators,
        visibleForEveryoneConfluenceSpace: createConfluenceForm.visibleToEveryone as boolean,
      },
      gitHubInput: {
        createGitHubRepository: createGitHubRepo,
        componentKind: createGitHubRepoForm.kind,
        componentName: createGitHubRepoForm.componentName,
        suffix: createGitHubRepoForm.suffix,
        componentType: createGitHubRepoForm.type,
        componentLifecycle: createGitHubRepoForm.lifecycle,
        gitHubRepositoryConfiguration: {
          contentType: createGitHubRepoForm.contentType as 'Empty' | 'Default Branch' | 'From Template',
          templateRepository: createGitHubRepoForm.template,
        },
      },
      currentUser: userProfile,
    };

    return request;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const request = buildCreateRequest();
    try {
      if (confirmData) {
        setLoadingSubmit(true);
        await projectService.createProject(request);
        setIsSuccessfulProjectCreation(true);
      }
      setLoadingSubmit(false);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      setMessage(errorMessage);
      setStatus('error');
      setLoadingSubmit(false);
      setIsSuccessfulProjectCreation(false);
      setOpen(true);
    }
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  if (membersLoading || loadingSubmit) {
    return <Progress />;
  }

  if (isSuccessfulProjectCreation) {
    return <SuccessProjectCreation />;
  }

  const checkActiveValidity = (activeStepParam: number) => {
    switch (activeStepParam) {
      case 0:
        return isValid;
      case 1:
        return isValid;
      case 2:
        return isValidJiraForm;
      case 3:
        return isValidConfluenceForm;
      case 4:
        return isValidGitHubRepo;
      case 5:
        return true;
      default:
        console.log('Validity Check');
        return;
    }
  };

  return (
    <Stack direction="column" justifyContent="space-around">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} onClick={() => handleStepClick(index)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        <CreateProjectInfo
          onChange={handleChangeProjectInfo}
          setIsValid={setIsValid}
          createProjectInfoForm={createProjectInfoForm}
          technologyOptions={additionalInfoOptionsValue.technologies}
          industryOptions={additionalInfoOptionsValue.industries}
          handleAddUrl={handleUrlAddition}
          deleteUrl={deleteUrl}
          urls={urls}
          urlTitleError={urlTitleError}
        />
      )}
      {activeStep === 1 && (
        <CreateTeam
          onChange={handleChangeTeam}
          setIsValid={setIsValid}
          teamMembersValue={teamMembersValue}
          createTeamForm={createTeamForm}
          projectType={createProjectInfoForm.projectType}
          everyoneAtSWOCanRead={createTeamForm.everyoneAtSWOCanRead}
        />
      )}
      {activeStep === 2 && (
        <CreateJiraProject
          onChange={handleChangeJiraProject}
          setIsValid={setIsValidJiraForm}
          isValid={isValidJiraForm}
          jiraProjectName={generateJiraProjectName()}
          displayJiraProjectKey={getJiraProjectKey()}
          jiraTeamMembersValue={jiraTeamMembersValue}
          createJiraProjectForm={createJiraProjectForm}
          isChecked={createJiraProject}
          setIsChecked={setCreateJiraProject}
          getTeamMembersForJira={getTeamMembersForJira}
        />
      )}
      {activeStep === 3 && (
        <CreateConfluenceSpace
          onChange={handleChangeConfluenceProject}
          setIsValid={setIsValidConfluenceForm}
          isValid={isValidConfluenceForm}
          confluenceProjectName={generateConfluenceProjectName()}
          displayProjectKeyConfluece={getConfluenceProjectKey()}
          confluenceUsersValue={confluenceUsersValue}
          createConfluenceForm={createConfluenceForm}
          isChecked={createConfluenceSpace}
          setIsChecked={setCreateConfluenceSpace}
          getTeamMembersForConfluence={getTeamMembersForConfluence}
        />
      )}
      {activeStep === 4 && (
        <CreateGithubRepo
          onChange={handleChangeGitHubRepo}
          setIsValid={setIsValidGitHubRepo}
          isValid={isValidGitHubRepo}
          createGitHubRepoForm={createGitHubRepoForm}
          templateOptions={templateOptionsGitHubRepo}
          templateOptionsLoading={templateOptionsLoading}
          isChecked={createGitHubRepo}
          setIsCheked={setCreateGitHubRepo}
        />
      )}
      {activeStep === 5 && (
        <ConfirmProjectCreation
          projectInfoData={{ createProjectInfoForm, urls }}
          teamData={{ createTeamForm }}
          jiraProjectData={{
            createJiraProjectForm: createJiraProject ? createJiraProjectForm : undefined,
          }}
          confluenceSpaceData={{
            createConfluenceForm: createConfluenceSpace ? createConfluenceForm : undefined,
          }}
          gitHubRepoData={{
            createGitHubRepoForm: createGitHubRepo ? createGitHubRepoForm : undefined,
          }}
        />
      )}
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={2}>
        {activeStep === 0 ? null : (
          <Button variant="outlined" onClick={handleBack}>
            Back
          </Button>
        )}
        {activeStep === 4 && (
          <Button variant="outlined" onClick={handleConfirm} disabled={!checkActiveValidity(activeStep)}>
            Confirm
          </Button>
        )}
        {activeStep === 5 && (
          <Button variant="outlined" onClick={handleSubmit}>
            Create
          </Button>
        )}
        {activeStep === 4 || activeStep === 5 ? null : (
          <Button
            variant="outlined"
            style={{ marginRight: '3rem' }}
            onClick={handleNext}
            disabled={!checkActiveValidity(activeStep)}
          >
            Next
          </Button>
        )}
      </Stack>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} autoHideDuration={15000} />
    </Stack>
  );
};

export default CreateProjectTable;
