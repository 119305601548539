import React from 'react';
import { ChangeDataTeam } from '../CreateTeam';
import { Box, Typography } from '@material-ui/core';
import { StyledRow, StyledField, StyledDataField } from '../../styled/styles';

interface TableTeamProps {
  createTeamForm: ChangeDataTeam;
}
export const TableTeam: React.FC<TableTeamProps> = createTeamForm => {
  const createDataTeam = (field: string, dataField: string[] | boolean) => {
    if (typeof dataField === 'boolean') {
      return { field, dataField: [dataField.toString()] };
    }
    return { field, dataField };
  };

  const rowsTeam = [
    createDataTeam('GitHub Repo Owners', createTeamForm.createTeamForm.gitHubOwners),
    createDataTeam('GitHub Repo Contributors', createTeamForm.createTeamForm.gitHubContributors),
    createDataTeam('GitHub Repo Readers', createTeamForm.createTeamForm.gitHubReaders),
    createDataTeam('Everyone@SWO can read', createTeamForm.createTeamForm.everyoneAtSWOCanRead),
  ];
  return (
    <Box>
      <Typography variant="h6">Team </Typography>
      {rowsTeam.map(row => (
        <StyledRow key={row.field}>
          <StyledField>{row.field}</StyledField>
          <StyledDataField>{Array.isArray(row.dataField) ? row.dataField.join(', ') : row.dataField}</StyledDataField>
        </StyledRow>
      ))}
    </Box>
  );
};
