import React, { useState, useEffect, SetStateAction, Dispatch, useContext } from 'react';
import * as FileSaver from 'file-saver';
import { Paper, Box, Typography, Button } from '@material-ui/core';
import { TextFieldFormInput, SelectFormInput, AppContext } from '@softwareone/plugin-project-management-react';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { z, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Stack from '@mui/material/Stack';

interface CustomerOption {
  label: string;
  value: string;
}

interface FilterTableProps {
  onFilterSubmit: Dispatch<SetStateAction<never[]>>;
  onLoading: Dispatch<SetStateAction<boolean>>;
  onMessage: Dispatch<SetStateAction<string>>;
  onStatus: Dispatch<SetStateAction<'success' | 'error'>>;
  onOpen: Dispatch<SetStateAction<boolean>>;
}

const FilterTable: React.FC<FilterTableProps> = ({ onFilterSubmit, onLoading, onMessage, onStatus, onOpen }) => {
  const [customers, setCustomers] = useState<CustomerOption[]>([]);
  const [formData, setFormData] = useState<FormInput>(() => {
    const savedFormData = localStorage.getItem('formData');
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          projectName: '',
          projectType: '',
          customerName: '',
        };
  });

  const {
    services: { reportsService, projectService },
  } = useContext(AppContext);

  const projectTypeOptions = [
    { label: 'Customer', value: 'Customer' },
    { label: 'Internal', value: 'Internal' },
    { label: 'Personal', value: 'Personal' },
  ];

  const formSchema = z.object({
    projectName: z.string(),
    projectType: z.enum(['', 'Customer', 'Internal', 'Personal'] as const),
    customerName: z.string(),
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const data = await projectService.getCustomers();
        const transformedCustomers = data.map((customer: string) => ({
          label: customer,
          value: customer,
        }));
        setCustomers(transformedCustomers);
      } catch (err) {
        console.error('Error fetching customers:', err);
        onMessage('An error occurred trying to get the current customers');
        onStatus('error');
        onOpen(true);
      }
    };
    fetchCustomers();
  }, []);

  type FormInput = TypeOf<typeof formSchema>;

  const { control, handleSubmit, reset, setValue } = useForm<FormInput>({
    resolver: zodResolver(formSchema),
    defaultValues: formData,
  });

  const projectType = useWatch({
    control,
    name: 'projectType',
    defaultValue: '',
  });

  useEffect(() => {
    if (projectType === 'Internal' || projectType === 'Personal') {
      setValue('customerName', '');
    }
  }, [projectType, setValue]);

  const onSubmit: SubmitHandler<FormInput> = async filters => {
    setFormData(filters);
    localStorage.setItem('formData', JSON.stringify(filters));
    onLoading(true);
    try {
      const data = await reportsService.getReports(filters.projectName, filters.projectType, filters.customerName);
      onLoading(false);
      onFilterSubmit(data);
    } catch (err) {
      console.error('Error fetching projects:', err);
      onMessage('An error occurred trying to get the projects');
      onStatus('error');
      onLoading(false);
      onOpen(true);
    }
  };

  const handleExport: SubmitHandler<FormInput> = async filters => {
    setFormData(filters);
    localStorage.setItem('formData', JSON.stringify(filters));
    onLoading(true);
    try {
      const dataBlob = await reportsService.exportReport(
        filters.projectName,
        filters.projectType,
        filters.customerName
      );
      if (dataBlob) {
        const reader = new FileReader();
        reader.readAsText(dataBlob);
        const fileName = 'project_report.xlsx';
        FileSaver.saveAs(dataBlob, fileName);
        onLoading(false);
        console.log('File downloaded successfully');
      }
      onLoading(false);
    } catch (err) {
      console.error('Error downloading projects:', err);
      onMessage('An error occurred trying to download projets');
      onStatus('error');
      onLoading(false);
      onOpen(true);
    }
  };

  const resetForm = () => {
    reset(formData);
  };

  const resetAll = () => {
    reset({
      projectName: '',
      projectType: '',
      customerName: '',
    });
    setFormData({
      projectName: '',
      projectType: '',
      customerName: '',
    });
    setValue('projectName', '');
    setValue('projectType', '');
    setValue('customerName', '');
    localStorage.removeItem('formData');

    handleSubmit(onSubmit)();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', mr: 1 }}>
        <Paper>
          <Box sx={{ mt: 2, ml: 2 }}>
            <Typography variant="h6">Filter by:</Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%' }}>
              <Stack spacing={2} sx={{ m: 2 }}>
                <TextFieldFormInput name="projectName" label="Project Name" control={control} disabled={false} />

                <SelectFormInput
                  label="Project Type"
                  name="projectType"
                  options={projectTypeOptions}
                  control={control}
                  disabled={false}
                />

                <SelectFormInput
                  label="Customer"
                  name="customerName"
                  options={customers}
                  control={control}
                  disabled={projectType === 'Internal' || projectType === 'Personal'}
                />
                <Button variant="outlined" fullWidth type="submit">
                  Filter
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  type="button"
                  onClick={() => {
                    resetAll();
                  }}
                >
                  Reset
                </Button>
              </Stack>
            </Box>
          </form>
        </Paper>
      </Box>
      <Box sx={{ mr: 1, mt: 3 }}>
        <Button
          variant="outlined"
          fullWidth
          type="submit"
          onClick={e => {
            e.preventDefault();
            handleSubmit(handleExport)();
            resetForm();
          }}
        >
          Export
        </Button>
      </Box>
    </>
  );
};

export default FilterTable;
