import { Card, List, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles } from '@material-ui/core';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import {
  useEntityList,
  EntityKindFilter,
  DefaultEntityFilters,
  EntityTypeFilter,
  EntityFilter,
  EntityLifecyclePicker,
} from '@backstage/plugin-catalog-react';
import { EntityKind, SpecType } from '../../../../types';
import { Select } from '@backstage/core-components';
import { Box, MenuItem, Typography } from '@mui/material';
import { IconComponent } from '@backstage/core-plugin-api';
import SettingsIcon from '@material-ui/icons/Settings';
import StarIcon from '@material-ui/icons/Star';
import { useAllEntitiesCount, useStarredEntitiesCount, useUserTeamGroups } from '../../../../hooks';
import { EntityMemberTeamFilter, EntityOwnerTeamFilter, EntityTeamFilter } from '../../../../filter';
import { UserFilterContext, UserFilterContextType } from '../../../../context';
import { useUserProfile } from '@backstage/plugin-user-settings';

const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .11)',
      boxShadow: 'none',
      margin: theme.spacing(1, 0, 1, 0),
    },
    title: {
      margin: theme.spacing(1, 0, 0, 1),
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 'bold',
    },
    listIcon: {
      minWidth: 30,
      color: theme.palette.text.primary,
    },
    menuItem: {
      minHeight: theme.spacing(6),
      '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, .08)!important',
      },
    },
    groupWrapper: {
      margin: theme.spacing(1, 1, 2, 1),
    },
  }),
  {
    name: 'CatalogReactUserListPicker',
  }
);

type SelectedTeamGroup = 'owned' | 'my' | 'starred' | 'all';

const typeFilterOptions = [
  { label: 'All', value: 'All' },
  { label: 'Service', value: 'Service' },
  { label: 'Website', value: 'Website' },
  { label: 'Library', value: 'Library' },
  { label: 'Web Application', value: 'Web Application' },
  { label: 'Mobile Application', value: 'Mobile Application' },
  { label: 'Business Intelligence', value: 'Business Intelligence' },
  { label: 'Machine Learning', value: 'Machine Learning' },
  { label: 'AI', value: 'AI' },
  { label: 'Function', value: 'Function' },
  { label: 'Low Code', value: 'Low Code' },
  { label: 'CMS', value: 'CMS' },
  { label: 'CRM', value: 'CRM' },
  { label: 'CLI', value: 'CLI' },
  { label: 'Infrastructure as Code', value: 'Infrastructure as Code' },
  { label: 'Automated Testing', value: 'Automated Testing' },
  { label: 'Documentation', value: 'Documentation' },
  { label: 'Other', value: 'Other' },
];

type ButtonGroup = {
  name: string;
  items: {
    id: SelectedTeamGroup;
    label: string;
    icon?: IconComponent;
  }[];
};

type EntityUserFilterProps = {
  entityKind: EntityKind;
};
type UserFilterType = {
  starred?: EntityFilter;
  myTeams?: EntityFilter;
  ownerTeams?: EntityFilter;
  memberOf?: EntityFilter;
  team?: EntityFilter;
};
type UserEntityFilter = DefaultEntityFilters & UserFilterType;
export const EntityUserGroupPicker = ({ entityKind }: EntityUserFilterProps) => {
  const { profile } = useUserProfile();

  const {
    updateFilters,
    queryParameters: { kind: kindParameter, ...userQueryParams },
    filters,
  } = useEntityList<UserEntityFilter>();

  const { value: teamGroup, loading: userTeamsGroupLoading } = useUserTeamGroups();
  const { count: entitiesCount, loading: entitiesCountLoading } = useAllEntitiesCount();
  const classes = useStyles();
  type SelectedTypeFilter = SpecType | 'All';
  type QueryParamUserFilter = {
    userFilterQuery: SelectedTeamGroup;
    typeQuery: SelectedTypeFilter | undefined;
  } | null;

  const queryParamUserFilter: QueryParamUserFilter = useMemo(() => {
    if (Object.keys(userQueryParams).length === 0) {
      return null;
    }
    let typeQuery: SelectedTypeFilter | undefined;
    if (userQueryParams.type) {
      typeQuery = userQueryParams.type as SelectedTypeFilter;
    }

    if (userQueryParams.ownerTeams) {
      return { userFilterQuery: 'owned', typeQuery };
    }
    if (userQueryParams.memberOf || userQueryParams.team || userQueryParams.myTeams) {
      return { userFilterQuery: 'my', typeQuery };
    }
    if (userQueryParams.starred) {
      return { userFilterQuery: 'starred', typeQuery };
    }
    return { userFilterQuery: 'all', typeQuery };
  }, [kindParameter, userQueryParams]);
  const isComponentOrApi = ['component', 'API'].includes(entityKind);

  const initialTypeFilter = queryParamUserFilter?.typeQuery ?? (isComponentOrApi ? 'All' : undefined);
  const [selectedTypeFilter, setSelectedTypeFilter] = useState<SelectedTypeFilter | undefined>(initialTypeFilter);
  const [selectedUserFilter, setSelectedUserFilter] = useState<SelectedTeamGroup>(
    queryParamUserFilter?.userFilterQuery ?? 'all'
  );
  const { updateUserFilter } = useContext(UserFilterContext) as UserFilterContextType;
  const {
    count: starredEntitiesCount,
    filter: starredEntitiesFilter,
    loading: loadingStarredEntities,
  } = useStarredEntitiesCount();

  const loading = userTeamsGroupLoading || loadingStarredEntities || entitiesCountLoading;

  const getFilter = () => {
    const filter: UserEntityFilter = {
      kind: new EntityKindFilter(entityKind),
      starred: undefined,
      ownerTeams: undefined,
      myTeams: undefined,
      memberOf: undefined,
      team: undefined,
      type: undefined,
      lifecycles: isComponentOrApi ? filters.lifecycles : undefined,
    };
    if (isComponentOrApi) {
      filter.type = new EntityTypeFilter(selectedTypeFilter === 'All' ? [] : (selectedTypeFilter as string));
    }
    if (selectedUserFilter === 'owned') {
      updateUserFilter('Owned');
      return {
        ...filter,
        ownerTeams: new EntityOwnerTeamFilter(teamGroup?.owners ?? ['']),
      };
    } else if (selectedUserFilter === 'my') {
      updateUserFilter('My');
      if (entityKind === 'user') {
        return {
          ...filter,
          memberOf: new EntityMemberTeamFilter(teamGroup?.allTeams ?? [''], profile?.displayName ?? ''),
        };
      }
      if (entityKind === 'group') {
        return {
          ...filter,
          team: new EntityTeamFilter(teamGroup?.allTeams ?? ['']),
        };
      }
      return {
        ...filter,
        myTeams: new EntityOwnerTeamFilter(teamGroup?.involved ?? ['']),
      };
    } else if (selectedUserFilter === 'all') {
      updateUserFilter('All');
      return {
        ...filter,
      };
    }
    updateUserFilter('Starred');
    return {
      ...filter,
      starred: starredEntitiesFilter,
    };
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    updateFilters(getFilter());
  }, [selectedUserFilter, selectedTypeFilter, loading]);

  const filterCounts = useMemo(() => {
    if (entityKind === 'user') {
      return {
        all: entitiesCount?.allEntitiesCount ?? 0,
        starred: starredEntitiesCount ?? 0,
        owned: entitiesCount?.ownedEntitiesCount ?? 0,
        my: entitiesCount?.myEntitiesCount ? entitiesCount.myEntitiesCount - 1 : 0,
      };
    }
    return {
      all: entitiesCount?.allEntitiesCount ?? 0,
      starred: starredEntitiesCount ?? 0,
      owned: entitiesCount?.ownedEntitiesCount ?? 0,
      my: entitiesCount?.myEntitiesCount,
    };
  }, [starredEntitiesCount, entitiesCount]);

  const filterGroupsOptions: ButtonGroup[] = useMemo(() => {
    if (entityKind === 'group') {
      return [
        {
          name: 'Filter',
          items: [
            {
              id: 'all',
              label: 'All',
            },
            {
              id: 'my',
              label: 'My',
              icon: SettingsIcon,
            },
            {
              id: 'starred',
              label: 'Starred',
              icon: StarIcon,
            },
          ],
        },
      ];
    }

    if (entityKind === 'user') {
      return [
        {
          name: 'Filter',
          items: [
            {
              id: 'all',
              label: 'All',
            },
            {
              id: 'my',
              label: 'My Teammates',
              icon: SettingsIcon,
            },
            {
              id: 'starred',
              label: 'Starred',
              icon: StarIcon,
            },
          ],
        },
      ];
    }

    if (entityKind === 'system') {
      return [
        {
          name: 'Filter',
          items: [
            {
              id: 'all',
              label: 'All',
            },
            {
              id: 'owned',
              label: 'Owned',
              icon: SettingsIcon,
            },
            {
              id: 'my',
              label: 'My',
              icon: SettingsIcon,
            },
            {
              id: 'starred',
              label: 'Starred',
              icon: StarIcon,
            },
          ],
        },
      ];
    }
    return [
      {
        name: 'Filter',
        items: [
          {
            id: 'all',
            label: 'All',
          },
          {
            id: 'owned',
            label: 'Owned',
            icon: SettingsIcon,
          },
          {
            id: 'my',
            label: 'My',
            icon: SettingsIcon,
          },
          {
            id: 'starred',
            label: 'Starred',
            icon: StarIcon,
          },
        ],
      },
    ];
  }, [entityKind]);

  return (
    <>
      {isComponentOrApi && (
        <Box pb={1} pt={1}>
          <Select
            label="Type"
            items={typeFilterOptions}
            selected={selectedTypeFilter}
            onChange={value => setSelectedTypeFilter(value as SelectedTypeFilter)}
          />
        </Box>
      )}
      <Card className={classes.root}>
        {filterGroupsOptions.map(group => (
          <Fragment key={group.name}>
            <Card className={classes.groupWrapper}>
              <List disablePadding dense role="menu" aria-label={group.name}>
                {group.items.map(item => (
                  <MenuItem
                    role="none presentation"
                    key={item.id}
                    divider
                    onClick={() => setSelectedUserFilter(item.id)}
                    selected={selectedUserFilter === item.id}
                    className={classes.menuItem}
                    data-testid={`user-picker-${item.id}`}
                    tabIndex={0}
                  >
                    {item.icon && (
                      <ListItemIcon className={classes.listIcon}>
                        <item.icon fontSize="small" />
                      </ListItemIcon>
                    )}
                    <ListItemText>
                      <Typography variant="body1">{item.label} </Typography>
                    </ListItemText>
                    <ListItemSecondaryAction>{filterCounts[item.id]}</ListItemSecondaryAction>
                  </MenuItem>
                ))}
              </List>
            </Card>
          </Fragment>
        ))}
      </Card>
      {isComponentOrApi && <EntityLifecyclePicker />}
    </>
  );
};
