import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { StyledRow, StyledField, StyledDataField } from '../../styled/styles';
import { ChangeDataConfluenceSpace } from '../CreateConfluenceSpace';

interface TableConfluenceSpaceProps {
  createConfluenceForm: ChangeDataConfluenceSpace;
}
export const TableConfluenceSpace: React.FC<TableConfluenceSpaceProps> = createConfluenceForm => {
  const createDataConfluenceSpace = (field: string, dataField: string | string[] | boolean | undefined) => {
    if (typeof dataField === 'boolean') {
      return { field, dataField: [dataField.toString()] };
    }
    return { field, dataField };
  };

  const confluenceSpaceKey = createConfluenceForm.createConfluenceForm.confluenceSpaceKey || '';
  const rowsConfluenceSpace = [
    createDataConfluenceSpace('Confluence Space Key', confluenceSpaceKey),
    createDataConfluenceSpace(
      'Confluence Space Administrators',
      createConfluenceForm.createConfluenceForm.confluenceAdministrators
    ),
    createDataConfluenceSpace(
      'Confluence Space Contributors',
      createConfluenceForm.createConfluenceForm.confluenceContributors
    ),
    createDataConfluenceSpace(
      'Confluence Space Customers',
      createConfluenceForm.createConfluenceForm.confluenceCustomers
    ),
    createDataConfluenceSpace('Visible to all employees', createConfluenceForm.createConfluenceForm.visibleToEveryone),
  ];

  return (
    <Box>
      <Typography variant="h6">Confluence Space</Typography>
      {rowsConfluenceSpace.map(row => (
        <StyledRow key={row.field}>
          <StyledField>{row.field}</StyledField>
          <StyledDataField>{Array.isArray(row.dataField) ? row.dataField.join(', ') : row.dataField}</StyledDataField>
        </StyledRow>
      ))}
    </Box>
  );
};
