export interface Column {
  id: 'projectName' | 'projectType' | 'customerName' | 'owners' | 'numberOfComponents' | 'languages';
  label: string;
  minWidth?: number;
  align?: string;
}

export const columns: Column[] = [
  { id: 'projectName', label: 'Project Name', minWidth: 200, align: 'left' },
  { id: 'projectType', label: 'Project Type', minWidth: 140, align: 'left' },
  {
    id: 'customerName',
    label: 'Customer',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'owners',
    label: 'Owner',
    minWidth: 180,
    align: 'left',
  },
  {
    id: 'numberOfComponents',
    label: 'Components',
    minWidth: 120,
    align: 'right',
  },
  {
    id: 'languages',
    label: 'Languages',
    minWidth: 170,
    align: 'left',
  },
];

export interface TableRows {
  projectName: string;
  projectType: string;
  customerName: string;
  owners: string;
  numberOfComponents: number;
  languages: string;
}

export const createData = (
  projectName: string,
  projectType: string,
  customerName: string,
  owners: string[],
  numberOfComponents: number,
  languages: string[]
): TableRows => {
  return {
    projectName,
    projectType,
    customerName,
    owners: owners.length > 0 ? owners.join(',  ') : '',
    numberOfComponents,
    languages: languages.length > 0 ? languages.join(',  ') : '',
  };
};
