import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useOrganizationJiraTeamMembers } from '../hook/useOrganizationJiraTeamMembers';
import { useEntity } from '@backstage/plugin-catalog-react';
import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { AlertSnackbar, AppContext, JiraItem } from '@softwareone/plugin-project-management-react';
import { Progress } from '@backstage/core-components';
import {
  addTeamMembers,
  extractGroupAdminInfo,
  processProjectTeamInfo,
  TeamResponse,
  UserObject,
} from '../utils/utils';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { Actor } from '@softwareone/plugin-project-management-common/src/types/jira/actor/actor';
import { UserModel } from '@softwareone/plugin-project-management-common/src/types/jira/user/userModel';
import { ProjectUserData, SystemEntity } from '@softwareone/plugin-project-management-common';

export const JiraProject = () => {
  const [jiraAdministrators, setJiraAdministrators] = useState<string[]>([]);
  const [jiraContributors, setJiraContributors] = useState<string[]>([]);
  const [jiraCustomers, setJiraCustomers] = useState<string[]>([]);
  const [jiraProjectLeadName, setJiraProjectLeadName] = useState<string>('');
  const [jiraAdministratorsActors, setJiraAdministratorActors] = useState<Actor[]>([]);
  const [jiraContributorsActors, setJiraContributorsActors] = useState<Actor[]>([]);
  const [jiraCustomersActors, setJiraCustomersActors] = useState<Actor[]>([]);
  const [open, setOpen] = useState(false);
  const [jiraLoading, setJiraLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [isFormValid, setIsFormValid] = useState(false);
  const { entity } = useEntity();
  const {
    confluenceSpaceKey,
    jiraProjectKey,
    name: currentSystemName,
    customer,
    title,
    creatorActiveDirectoryId,
  } = entity.metadata as SystemEntity;
  const [jiraProjectKeyInput, setJiraProjectKeyInput] = useState<string | undefined>(
    (confluenceSpaceKey as string) || ''
  );
  const [jiraProjectTemplate, setJiraProjectTemplate] = useState<string | undefined>('');
  const [visibleForEveryone, setVisibleForEveryone] = useState<boolean | undefined>(true);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [prevUsers, setPrevUsers] = useState<{
    gitHubContributors: { login: string }[];
    gitHubReaders: { login: string }[];
    gitHubOwners: { login: string }[];
  }>();
  const [prevUsersLoading, setPrevUsersLoading] = useState(false);
  const [dispatchLoading, setDispatchLoading] = useState<boolean>(false);
  const { loading, error, value } = useOrganizationJiraTeamMembers();
  const {
    services: { jiraService, projectService },
  } = useContext(AppContext);

  const projectKey = currentSystemName;
  const gitHubApi = useApi(githubAuthApiRef);
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  const getUsers = useCallback(async () => {
    setJiraLoading(true);
    try {
      const data = await jiraService.getTeamMembers(projectKey);
      const projectUserData = data as ProjectUserData;
      projectUserData?.jiraProjectLead && setJiraProjectLeadName(projectUserData.jiraProjectLead.displayName);
      projectUserData?.projectRolesUserData?.jiraContributors &&
        setJiraContributorsActors(projectUserData.projectRolesUserData?.jiraContributors);
      projectUserData?.projectRolesUserData?.jiraAdministrators &&
        setJiraAdministratorActors(projectUserData.projectRolesUserData?.jiraAdministrators);
      projectUserData?.projectRolesUserData?.jiraCustomers &&
        setJiraCustomersActors(projectUserData.projectRolesUserData?.jiraCustomers);
      setJiraLoading(false);
    } catch (err) {
      setJiraLoading(false);
      console.error('Error:', err);
      setMessage('An error occurred trying to get the current Jira projects');
      setStatus('error');
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!jiraProjectKey) {
      return;
    }
    getUsers();
  }, [getUsers]);

  const isUserOwner = useCallback(async () => {
    if (formattedProfile.authProvider === 'GitHub') {
      const profileData = await gitHubApi.getProfile();
      const teamData: TeamResponse = await processProjectTeamInfo(
        projectService,
        currentSystemName,
        hasGithubAdmins,
        setIsOwner,
        profileData
      );
      setPrevUsers({
        gitHubContributors: teamData.gitHubContributors,
        gitHubReaders: teamData.gitHubReaders,
        gitHubOwners: teamData.gitHubOwners,
      });
      return;
    }

    if (formattedProfile.id === creatorActiveDirectoryId) {
      setIsOwner(true);
      const data = await projectService.getProjectTeamInfo(projectKey);
      setPrevUsers(data);
    }
  }, [profile, hasGithubAdmins]);

  useEffect(() => {
    isUserOwner();
  }, [isUserOwner]);

  const extractInitialNames = (rawFormat: Actor[]) => {
    return rawFormat.map(entry => entry.displayName);
  };

  useEffect(() => {
    setJiraContributors(extractInitialNames(jiraContributorsActors));
    setJiraAdministrators(extractInitialNames(jiraAdministratorsActors));
    setJiraCustomers(extractInitialNames(jiraCustomersActors));
  }, [jiraAdministratorsActors, jiraContributorsActors, jiraCustomersActors]);

  const getActors = (displayNames: string[], actors: Actor[]) => {
    const formattedActors: Actor[] = [];
    displayNames.forEach(displayName => {
      const foundActor = actors.find(formattedActor => formattedActor.displayName === displayName);
      if (foundActor) {
        formattedActors.push(foundActor);
        return;
      }
      const foundValue = (value as UserModel[]).find(entry => entry.displayName === displayName);
      if (!foundValue) {
        return;
      }
      const actor: Actor = {
        id: 0,
        displayName,
        type: 'atlassian-user-role-actor',
        actorUser: { accountId: foundValue.accountId },
      };
      formattedActors.push(actor);
    });
    return formattedActors;
  };

  type FormattedTeamMembers<T extends Actor | string> = {
    [key in
      | 'jiraCustomers'
      | 'jiraContributors'
      | 'jiraAdministrators'
      | 'jiraProjectLeadId']: key extends 'jiraProjectLeadId' ? string : T[];
  };

  const getFormattedTeamMembers = (withActorInfo?: boolean) => {
    const newJiraProjectLeadId = (value as UserModel[]).find(val => val.displayName === jiraProjectLeadName)!.accountId;
    const data = {
      jiraProjectLeadId: newJiraProjectLeadId,
      jiraContributors: getActors(jiraContributors, jiraContributorsActors),
      jiraAdministrators: getActors(jiraAdministrators, jiraAdministratorsActors),
      jiraCustomers: getActors(jiraCustomers, jiraCustomersActors),
    };
    if (!withActorInfo) {
      return {
        ...data,
        jiraContributors: getActors(jiraContributors, jiraContributorsActors).map(actor => actor.actorUser.accountId),
        jiraAdministrators: getActors(jiraAdministrators, jiraAdministratorsActors).map(
          actor => actor.actorUser.accountId
        ),
        jiraCustomers: getActors(jiraCustomers, jiraCustomersActors).map(actor => actor.actorUser.accountId),
      };
    }
    return data;
  };

  const createJiraProject = async () => {
    setJiraLoading(true);
    const { jiraProjectLeadId, ...restUsers } = getFormattedTeamMembers() as FormattedTeamMembers<string>;
    const createProject = {
      projectKey,
      jiraProjectKey: jiraProjectKeyInput as string,
      templateName: jiraProjectTemplate as string,
      visibleForEveryone: !!visibleForEveryone,
      jiraProjectLead: jiraProjectLeadId,
      ...restUsers,
    };
    try {
      await jiraService.createJiraProject(createProject);
      setJiraLoading(false);
      setMessage(
        'Your Jira project has been successfully created. It takes time to be in sync with SoftwareOne DevOps platform'
      );
      setStatus('success');
      setOpen(true);
    } catch (err) {
      setJiraLoading(false);
      console.error('Error:', err);
      setMessage('An error occurred during the Jira project creation');
      setStatus('error');
      setOpen(true);
    }
  };
  const updateJiraTeamMembers = async () => {
    const updateProject = {
      projectKey,
      ...(getFormattedTeamMembers(true) as FormattedTeamMembers<Actor>),
    };
    setJiraLoading(true);
    try {
      await jiraService.updateTeamMembers(updateProject);
      setJiraLoading(false);
      setMessage('Your Jira project has been successfully updated');
      setStatus('success');
      setOpen(true);
    } catch (err) {
      setJiraLoading(false);
      console.error('Error:', err);
      setMessage('An error occurred during the Jira project update');
      setStatus('error');
      setOpen(true);
    }
  };

  const formattedValue: string[] = [];
  if (Array.isArray(value))
    (value as UserModel[]).forEach(entry => {
      if (entry?.displayName !== undefined) formattedValue.push(entry.displayName);
    });

  const handleUpdateSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await updateJiraTeamMembers();
  };

  const handleCreateSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    await createJiraProject();
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (error) {
      console.log('Error:', error);
      setMessage('An error occurred trying to get all potential jira members');
      setStatus('error');
      setOpen(true);
    } else if (!Array.isArray(value)) {
      const ex = new Error(value.message);
      console.log('Error:', ex);
      setMessage('An error occurred trying to get all potential jira members');
      setStatus('error');
      setOpen(true);
    }
  }, [loading, error, value]);

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  type ChangeData = {
    jiraProjectTemplate?: string;
    jiraProjectKey?: string;
    jiraProjectLeadName: string;
    jiraContributors: string[];
    jiraCustomers: string[];
    jiraAdministrators: string[];
    visibleForEveryone?: boolean;
  };
  const handleChange = (e: ChangeData) => {
    setJiraProjectTemplate(e.jiraProjectTemplate || '');
    setJiraProjectKeyInput(e.jiraProjectKey);
    setJiraProjectLeadName(e.jiraProjectLeadName);
    setJiraContributors(e.jiraContributors);
    setJiraAdministrators(e.jiraAdministrators);
    setJiraCustomers(e.jiraCustomers);
    setVisibleForEveryone(e.visibleForEveryone);
  };
  const generateJiraProjectName = () => {
    return `${customer}-${title}`;
  };
  const handleValidityChange = (e: boolean) => {
    setIsFormValid(e);
  };

  const getEmailsUserNames = async () => {
    try {
      setPrevUsersLoading(true);
      const data = projectService.getOrgUsers();
      setPrevUsersLoading(false);
      return data;
    } catch (e1) {
      console.error('Fetch error:', e1);
    }
  };

  const getJiraUserNames = async () => {
    try {
      return await jiraService.getJiraOrgUsers();
    } catch (e) {
      console.error('Fetch error:', e);
    }
  };

  const performActions = async () => {
    setDispatchLoading(true);
    const userEmail = await getEmailsUserNames();
    const jiraEmail = await getJiraUserNames();
    if (!prevUsersLoading) {
      addTeamMembers(userEmail, jiraEmail, prevUsers, setJiraAdministrators, setJiraContributors, setJiraCustomers);
    }
    setDispatchLoading(false);
  };

  if (loading || jiraLoading || dispatchLoading) {
    return <Progress />;
  }

  if (!jiraProjectKey) {
    return (
      <>
        <form onSubmit={handleCreateSubmit}>
          <Box>
            <Paper
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 1,
              }}
            >
              <JiraItem
                jiraProjectName={generateJiraProjectName()}
                jiraProjectKey={jiraProjectKeyInput}
                jiraProjectTemplate={jiraProjectTemplate}
                jiraProjectLeadName={jiraProjectLeadName}
                jiraAdministrators={jiraAdministrators}
                withSharedConfluenceSpace={!!confluenceSpaceKey}
                jiraContributors={jiraContributors}
                jiraCustomers={jiraCustomers}
                userList={formattedValue}
                onChange={handleChange}
                onValidityChange={handleValidityChange}
                visibleForEveryone={visibleForEveryone}
                isDisabled={!isOwner}
                getTeamMembersforJira={performActions}
              />
              <Button sx={{ m: '10px' }} variant="outlined" type="submit" disabled={!isFormValid || !isOwner}>
                Create
              </Button>
            </Paper>
          </Box>
        </form>
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </>
    );
  }

  return (
    <>
      <form onSubmit={handleUpdateSubmit}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 1,
          }}
        >
          <JiraItem
            jiraProjectLeadName={jiraProjectLeadName}
            jiraAdministrators={jiraAdministrators}
            jiraContributors={jiraContributors}
            jiraCustomers={jiraCustomers}
            userList={formattedValue}
            onValidityChange={handleValidityChange}
            onChange={handleChange}
            enableJiraProjectInfo={false}
            isDisabled={!isOwner}
            getTeamMembersforJira={performActions}
          />
          <Button sx={{ m: '10px' }} variant="outlined" disabled={!isFormValid || !isOwner} type="submit">
            Save
          </Button>
        </Paper>
      </form>
      <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
    </>
  );
};
