import React, { useContext, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { MultiValueInput } from '../../fields/multiValueInputField/MultiValueInput';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import * as _ from 'lodash';
import { FormGroup } from '@material-ui/core';
import { SingleValueInput } from '../../fields/singleValueInputField/SingleValueInput';
import { AppContext } from '../../../context';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';

type JiraItemProps = {
  jiraProjectKey?: string;
  jiraProjectName?: string;
  jiraProjectTemplate?: string;
  jiraProjectLeadName: string;
  jiraAdministrators: string[];
  jiraContributors: string[];
  jiraCustomers: string[];
  userList: string[];
  withSharedConfluenceSpace?: boolean;
  onValidityChange: (e: boolean) => void;
  enableJiraProjectInfo?: boolean;
  onChange: (e: SelectedUsers) => void;
  isDisabled: boolean;
  visibleForEveryone?: boolean;
  getTeamMembersforJira?: () => void;
};

type SelectedUsers = Omit<
  JiraItemProps,
  | 'userList'
  | 'onValidityChange'
  | 'enableJiraProjectInfo'
  | 'onChange'
  | 'jiraProjectName'
  | 'isDisabled'
  | 'withSharedConfluenceSpace'
>;
type Key =
  | 'jiraProjectLeadName'
  | 'jiraAdministrators'
  | 'jiraCustomers'
  | 'jiraContributors'
  | 'jiraProjectTemplate'
  | 'visibleForEveryone';

export const JiraItem = ({
  userList,
  jiraProjectKey,
  jiraProjectName,
  jiraProjectTemplate,
  withSharedConfluenceSpace,
  jiraProjectLeadName,
  jiraAdministrators,
  jiraContributors,
  jiraCustomers,
  visibleForEveryone,
  onValidityChange,
  onChange,
  isDisabled = false,
  enableJiraProjectInfo = true,
  getTeamMembersforJira,
}: JiraItemProps) => {
  const [errorMessage, setErrorMessage] = useState<null | string>(withSharedConfluenceSpace ? '' : null);
  const {
    services: { jiraService },
  } = useContext(AppContext);
  const templateOptions = ['scrum', 'kanban', 'ADO-Scrum', 'ADO-Agile', 'ADO-Basic', 'ADO-CMMI'];

  const checkJiraProjectKey = async (jiraProjectKeyParam: string) => {
    try {
      await jiraService.checkJiraProjectKey(jiraProjectKeyParam);
      setErrorMessage('');
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message);
      }
    }
  };

  const debouncedCheckJiraProjectKey = _.debounce(checkJiraProjectKey, 300);

  const handleJiraProjectKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      jiraProjectLeadName: jiraProjectLeadName,
      jiraAdministrators: jiraAdministrators,
      jiraContributors: jiraContributors,
      jiraProjectTemplate,
      jiraCustomers: jiraCustomers,
      visibleForEveryone,
      jiraProjectKey: e.target.value,
    });
    debouncedCheckJiraProjectKey(e.target.value);
  };

  useEffect(() => {
    if (enableJiraProjectInfo) {
      const validityCondition =
        errorMessage !== null && !errorMessage && !!jiraProjectTemplate && !!jiraProjectLeadName;
      onValidityChange(validityCondition);
      return;
    }
    onValidityChange(!!jiraProjectLeadName);
  }, [errorMessage, jiraProjectTemplate, jiraProjectLeadName]);

  const handleValueChange = (key: Key, value: string[] | string | boolean) => {
    const usersToSelect = {
      jiraProjectLeadName: jiraProjectLeadName,
      jiraAdministrators: jiraAdministrators,
      jiraContributors: jiraContributors,
      jiraCustomers: jiraCustomers,
      jiraProjectTemplate,
      jiraProjectKey,
      visibleForEveryone,
      [key]: value,
    };
    onChange(usersToSelect);
  };

  return (
    <>
      {enableJiraProjectInfo && (
        <>
          <Box sx={{ display: 'grid', gap: 3, mx: 2, mt: 2 }}>
            <FlexContainer>
              <TextField
                value={jiraProjectName}
                fullWidth
                name="jiraProjectName"
                label="Jira Project Name"
                variant="outlined"
                disabled
              />
              <Box sx={{ ml: 1 }}>
                <InfoToolTip text="The name of the Jira project - generated automatically from the customer name and the project title." />
              </Box>
            </FlexContainer>
            <FlexContainer>
              <TextField
                variant="standard"
                id="filled-error"
                fullWidth
                name="jiraProjectKey"
                label="Jira Project Key"
                value={jiraProjectKey}
                required
                disabled={isDisabled || withSharedConfluenceSpace}
                error={isDisabled ? false : !!errorMessage}
                helperText={isDisabled ? '' : errorMessage}
                onChange={handleJiraProjectKeyChange}
              />
              <Box sx={{ mt: 2, ml: 1 }}>
                <InfoToolTip
                  text={
                    'The key which will uniquely identify the project in Jira.\nValid format <two-letter ISO country code><at least 1 and at most 8 letters and digits>.'
                  }
                />
              </Box>
            </FlexContainer>
          </Box>
          <FlexContainer>
            <SingleValueInput
              label="Jira Project Template *"
              data={templateOptions}
              value={jiraProjectTemplate}
              placeholder="Select Template"
              isDisabled={isDisabled}
              onChange={e => handleValueChange('jiraProjectTemplate', e)}
            />
            <Box sx={{ mt: 8, mr: 2 }}>
              <InfoToolTip text="The template to create the new Jira project from (Scrum or Kanban) or the template project which the new Jira project will share configuration with (the last four choices)." />
            </Box>
          </FlexContainer>
        </>
      )}
      <FlexContainer>
        <SingleValueInput
          label="Jira Project Lead *"
          data={userList}
          value={jiraProjectLeadName}
          placeholder="Select User"
          isDisabled={isDisabled}
          onChange={e => handleValueChange('jiraProjectLeadName', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Jira user who will lead the project - e.g., the project manager or the scrum master of the Jira project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="Jira Project Administrators"
          data={userList}
          value={jiraAdministrators}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('jiraAdministrators', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Jira users who can configure and administer the Jira project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="Jira Project Contributors"
          data={userList}
          value={jiraContributors}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('jiraContributors', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Jira users who can create and edit issues in the Jira project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="Jira Project Customers"
          data={userList}
          value={jiraCustomers}
          placeholder="Select Users"
          isDisabled={isDisabled}
          onValueChange={e => handleValueChange('jiraCustomers', e)}
        />
        <Box sx={{ mt: 8, mr: 2 }}>
          <InfoToolTip text="The Jira users who can only view issues in the Jira project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <Box sx={{ m: 2 }}>
          <Button variant="outlined" disabled={isDisabled} onClick={getTeamMembersforJira}>
            Copy from Team
          </Button>
        </Box>
        <InfoToolTip text="Use this button to automatically populate the administrators, contributors and customers from the owners, contributors and readers teams of the project respectively. The already selected users in these fields will be removed." />
      </FlexContainer>
      {enableJiraProjectInfo && (
        <FlexContainer>
          <FormGroup>
            <Box sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={isDisabled}
                    checked={visibleForEveryone}
                    onChange={e => handleValueChange('visibleForEveryone', e.target.checked)}
                  />
                }
                label="Visible to all employees"
              />
              <InfoToolTip text="If enabled, all SoftwareOne Jira users will be allowed to view issues in the Jira project." />
            </Box>
          </FormGroup>
        </FlexContainer>
      )}
    </>
  );
};
