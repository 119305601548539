import { HttpService } from './http-service';

export class ReportsService {
  constructor(private readonly httpService: HttpService) {}

  public async getReports(projectName: string, projectType: string, customerName: string) {
    const apiUrl = `/projects/reports/projects?${new URLSearchParams({
      projectName,
      projectType,
      customerName,
    })}`;
    const res = await this.httpService.get(apiUrl);
    const data = await res.json();
    if (!res.ok) {
      throw new Error(data.error);
    }
    return data;
  }

  public async exportReport(projectName: string, projectType: string, customerName: string) {
    const apiUrl = `/projects/exports/projects?${new URLSearchParams({
      projectName,
      projectType,
      customerName,
    })}`;
    const res = await this.httpService.get(apiUrl);
    if (!res.ok) {
      const jsonData = await res.json();
      throw new Error(jsonData.error);
    }
    return res.blob();
  }
}
