import React, { useEffect } from 'react';
import { MultiValueInput } from '../../fields/multiValueInputField/MultiValueInput';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FormGroup } from '@material-ui/core';
import { FlexContainer, InfoToolTip } from '../../infoToolTip/InfoToolTip';

type TeamsItemProps = {
  gitHubOwners: string[];
  gitHubContributors: string[];
  gitHubReaders: string[];
  isDisabled: boolean;
  allTeamsData: any;
  everyoneAtSWOCanRead: boolean;
  projectType: string;
  onValidityChange: (e: boolean) => void;
  onChange: (e: SelectedUsersWithMetadata) => void;
};

type Key = 'gitHubOwners' | 'gitHubContributors' | 'gitHubReaders' | 'everyoneAtSWOCanRead';

type SelectedUsersWithMetadata = Pick<TeamsItemProps, Key>;
export const TeamsItem = ({
  gitHubOwners,
  gitHubContributors,
  allTeamsData,
  gitHubReaders,
  isDisabled = false,
  everyoneAtSWOCanRead,
  projectType,
  onChange,
  onValidityChange,
}: TeamsItemProps) => {
  const optionMapper = (member: { username: string; email: string }) => {
    return {
      value: member.username,
      label: `${member.username} (${member.email})`,
      data: { email: member.email },
    };
  };

  useEffect(() => {
    onValidityChange(true);
  }, []);

  const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
    if (input) {
      return (
        candidate.value.toLowerCase().includes(input.toLowerCase()) ||
        candidate.data.data.email.toLowerCase().includes(input.toLowerCase())
      );
    }
    return true;
  };

  const handleValueChange = (key: Key, value: string[] | boolean) => {
    const usersToSelect = {
      gitHubOwners: gitHubOwners,
      gitHubContributors: gitHubContributors,
      gitHubReaders: gitHubReaders,
      everyoneAtSWOCanRead,
      [key]: value,
    };
    onChange(usersToSelect);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 2 }}>
      <FlexContainer>
        <MultiValueInput
          label="GitHub Repo Owners"
          data={allTeamsData}
          value={gitHubOwners}
          mapperFn={optionMapper}
          filterOptions={filterOptions}
          onValueChange={e => handleValueChange('gitHubOwners', e)}
          isDisabled={isDisabled}
        />
        <Box sx={{ mt: 8 }}>
          <InfoToolTip
            text={
              'The GitHub users who can edit the project in the SoftwareOne DevOps Platform and perform some administrative tasks on its repositories in GitHub.\nThe user who creates the projects is automatically added to this team.'
            }
          />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="GitHub Repo Contributors"
          data={allTeamsData}
          value={gitHubContributors}
          onValueChange={e => handleValueChange('gitHubContributors', e)}
          mapperFn={optionMapper}
          filterOptions={filterOptions}
          isDisabled={isDisabled}
        />
        <Box sx={{ mt: 8 }}>
          <InfoToolTip text="The GitHub users who can commit changes in the GitHub repositories of the project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <MultiValueInput
          label="GitHub Repo Readers"
          data={allTeamsData}
          value={gitHubReaders}
          onValueChange={e => handleValueChange('gitHubReaders', e)}
          mapperFn={optionMapper}
          filterOptions={filterOptions}
          isDisabled={isDisabled}
        />
        <Box sx={{ mt: 8 }}>
          <InfoToolTip text="The GitHub users who can only view the the GitHub repositories of the project." />
        </Box>
      </FlexContainer>
      <FlexContainer>
        <FormGroup>
          <Box
            sx={{
              m: 2,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={e => handleValueChange('everyoneAtSWOCanRead', e.target.checked)}
                  disabled={isDisabled || projectType === 'Internal' || projectType === 'Personal'}
                  checked={everyoneAtSWOCanRead}
                />
              }
              label="Everyone@SWO can read"
            />
            <InfoToolTip text="If enabled, all members of the SoftwareOne organization and the SoftwareOne team in GitHub will be allowed to view the GitHub repositories of the project. Always enabled for internal and personal projects." />
          </Box>
        </FormGroup>
      </FlexContainer>
    </Box>
  );
};
