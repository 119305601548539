import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface ProjectInfoTableUrlProps {
  urls: {
    url: string;
    title: string;
  }[];
  deleteUrl: (indexToDelete: number) => void;
  isOwner: boolean | undefined;
}

interface UrlEntry {
  url: string;
  title: string;
}

const ProjectInfoUrlTable: React.FC<ProjectInfoTableUrlProps> = ({
  urls,
  deleteUrl,
  isOwner,
}: ProjectInfoTableUrlProps) => {
  return (
    <>
      {urls.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {urls.map((urlEntry: UrlEntry, index: number) => (
                <TableRow key={index}>
                  <TableCell>{urlEntry.title}</TableCell>
                  <TableCell>
                    <a href={urlEntry.url} target="_blank" rel="noopener noreferrer">
                      {urlEntry.url}
                    </a>
                  </TableCell>
                  <TableCell>
                    <Button disabled={!isOwner} color="error" onClick={() => deleteUrl(index)}>
                      <DeleteOutlineIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ProjectInfoUrlTable;
