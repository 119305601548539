import React, { useState, useEffect, useContext } from 'react';
import {
  TextFieldFormInput,
  AlertSnackbar,
  SelectFormInput,
  AppContext,
} from '@softwareone/plugin-project-management-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEntity } from '@backstage/plugin-catalog-react';
import { z, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Paper, Typography, TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useApi, githubAuthApiRef } from '@backstage/core-plugin-api';
import Stack from '@mui/material/Stack';
import { Progress } from '@backstage/core-components';
import { useUserProfile } from '@backstage/plugin-user-settings';
import {
  checkUserOwnership,
  extractGroupAdminInfo,
  extractSonarValues,
  UserObject,
  SonarInstance,
} from '../utils/utils';
import { SystemEntity } from '@softwareone/plugin-project-management-common';

interface Technology {
  name: string;
}

export const APIInfoCard = () => {
  const { entity } = useEntity();
  const { name: currentAPIName, creatorActiveDirectoryId } = entity.metadata as SystemEntity;
  const { system = '', lifecycle = '', type = '' } = entity.spec || {};
  const kind = entity.kind || '';
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState<'success' | 'error'>('success');
  const [open, setOpen] = useState(false);
  const [isOwner, setIsOwner] = useState<boolean | undefined>(false);
  const [sonarInstances, setSonarInstances] = useState<SonarInstance[]>([]);
  const gitHubApi = useApi(githubAuthApiRef);
  const { backstageIdentity, profile } = useUserProfile();
  const formattedProfile = { id: '', authProvider: '', ...profile };
  const {
    services: { projectService, componentService },
  } = useContext(AppContext);
  const kindOptions = [
    { label: 'Component', value: 'Component' },
    { label: 'API', value: 'API' },
  ];

  const typeOptions = [
    { label: 'Service', value: 'Service' },
    { label: 'Website', value: 'Website' },
    { label: 'Library', value: 'Library' },
    { label: 'Web Application', value: 'Web Application' },
    { label: 'Mobile Application', value: 'Mobile Application' },
    { label: 'Business Intelligence', value: 'Business Intelligence' },
    { label: 'Machine Learning', value: 'Machine Learning' },
    { label: 'AI', value: 'AI' },
    { label: 'Function', value: 'Function' },
    { label: 'Low Code', value: 'Low Code' },
    { label: 'CMS', value: 'CMS' },
    { label: 'CRM', value: 'CRM' },
    { label: 'CLI', value: 'CLI' },
    { label: 'Infrastructure as Code', value: 'Infrastructure as Code' },
    { label: 'Automated Testing', value: 'Automated Testing' },
  ];

  const sonarInstancesOptions = sonarInstances.map(instance => ({
    label: instance.name,
    value: instance.name,
  }));

  const lifecycleOptions = [
    { label: 'experimental', value: 'experimental' },
    { label: 'production', value: 'production' },
    { label: 'deprecated', value: 'deprecated' },
  ];

  const formSchema = z.object({
    kind: z.enum(['Component', 'API'] as const),
    type: z.enum([
      'Service',
      'Website',
      'Library',
      'Web Application',
      'Mobile Application',
      'Business Intelligence',
      'Machine Learning',
      'AI',
      'Function',
      'Low Code',
      'CMS',
      'CRM',
      'CLI',
      'Infrastructure as Code',
      'Automated Testing',
    ] as const),
    lifecycle: z.enum(['experimental', 'production', 'deprecated'] as const),
    extractApiSuffix: z.string().max(32, { message: "Suffix can't exceed 32 characters" }),
    extractApiName: z.string().min(1, { message: "API Name can't be empty" }).max(32, {
      message: "API Name can't exceed 32 characters",
    }),
    projectKeySonar: z.string().optional(),
    selectedSonarInstance: z.string().optional(),
  });

  type FormInput = TypeOf<typeof formSchema>;

  const splitApiName = currentAPIName.split('-');
  const extractApiName = splitApiName[2];
  const extractApiSuffix = splitApiName.length >= 4 ? splitApiName[3] : '';

  const extractedApiTechnologies: Technology[] | undefined =
    (entity?.metadata.technologies as unknown as Technology[]) || undefined;

  const extractApiTechName: string[] = (extractedApiTechnologies ?? []).map(tech => tech.name);

  const sonarAnnotationsValue = entity.metadata?.annotations?.['sonarqube.org/project-key'];
  const { extractSonarInstance, extractProjectKeySonar } = extractSonarValues(sonarAnnotationsValue, sonarInstances);

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormInput>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    defaultValues: {
      kind: kind as FormInput['kind'],
      type: type as FormInput['type'],
      lifecycle: lifecycle as FormInput['lifecycle'],
      extractApiName: extractApiName,
      extractApiSuffix: extractApiSuffix,
      projectKeySonar: extractProjectKeySonar,
      selectedSonarInstance:
        typeof extractSonarInstance === 'string' ? extractSonarInstance : extractSonarInstance?.name ?? undefined,
    },
  });

  useEffect(() => {
    if (typeof extractSonarInstance === 'string') {
      setValue('selectedSonarInstance', extractSonarInstance);
    }
  }, [extractSonarInstance]);

  const hasApiSuffixError = errors !== null && errors.hasOwnProperty('extractApiSuffix');
  const hasApiNameError = errors !== null && errors.hasOwnProperty('extractApiName');

  const userObject: UserObject = {
    type: backstageIdentity?.type || '',
    userEntityRef: backstageIdentity?.userEntityRef || '',
    ownershipEntityRefs: backstageIdentity?.ownershipEntityRefs || [],
  };

  const hasGithubAdmins: boolean = extractGroupAdminInfo(userObject);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await componentService.getSonarInstances();
        const transformedInstances: SonarInstance[] = response.sonarqube.instances.map((instance: any) => ({
          name: instance,
        }));

        setSonarInstances(transformedInstances);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (typeof system !== 'string' || !profile) return;
    const isUserOwner = async () => {
      await checkUserOwnership(
        await gitHubApi.getProfile(),
        projectService,
        system,
        formattedProfile,
        hasGithubAdmins,
        setIsOwner,
        creatorActiveDirectoryId
      );
    };
    isUserOwner();
  }, [hasGithubAdmins, profile]);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    reset();
    setSubmitLoading(true);
    const request = {
      projectKey: system as string,
      componentKey: currentAPIName,
      componentName: data.extractApiName,
      suffix: data.extractApiSuffix,
      kind: data.kind,
      type: data.type,
      lifecycle: data.lifecycle,
      sonarInstanceName: data.selectedSonarInstance,
      sonarProjectKey: data.projectKeySonar,
    };
    try {
      await componentService.updateComponent(request);
      setMessage('API Info is successfully updated');
      setStatus('success');
      setSubmitLoading(false);
      setOpen(true);
    } catch (err) {
      let errorMessage = `An unknown error occurred during the API info update`;
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setMessage(errorMessage);
      setStatus('error');
      setSubmitLoading(false);
      setOpen(true);
    }
  };

  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if (submitLoading) {
    return <Progress />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>
      <Paper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} sx={{ m: 4 }}>
            <SelectFormInput label="Kind" name="kind" options={kindOptions} control={control} disabled={!isOwner} />
            <TextFieldFormInput name="extractApiName" control={control} label="API Name" disabled={!isOwner} />
            <TextFieldFormInput name="extractApiSuffix" control={control} label="Suffix" disabled={!isOwner} />
            <SelectFormInput label="Type" name="type" options={typeOptions} control={control} disabled={!isOwner} />
            <SelectFormInput
              label="Lifecycle"
              name="lifecycle"
              options={lifecycleOptions}
              control={control}
              disabled={!isOwner}
            />
            <SelectFormInput
              label="Sonar Instance"
              name="selectedSonarInstance"
              options={sonarInstancesOptions}
              control={control}
              disabled={!isOwner}
            />
            <TextFieldFormInput
              name="projectKeySonar"
              control={control}
              label="Sonar Project Key"
              disabled={!isOwner}
            />
            <Box>
              <Autocomplete
                multiple
                disabled
                options={extractApiTechName}
                defaultValue={extractApiTechName}
                readOnly
                renderInput={params => <TextField {...params} label="Technologies" variant="outlined" disabled />}
              />
              <Typography style={{ color: 'darkgray' }}>
                *Technologies are updated automatically from the GitHub repository of the component at a certain period
              </Typography>
            </Box>
            <Button
              variant="outlined"
              fullWidth
              type="submit"
              disabled={!isOwner || hasApiSuffixError || hasApiNameError}
            >
              Save
            </Button>
          </Stack>
        </form>
        <AlertSnackbar open={open} onClose={handleClose} severity={status} message={message} />
      </Paper>
    </Box>
  );
};
